import React, { Component } from "react";
import AsyncSelect from 'react-select/async';
import { getGetRequestOptions } from "../components/GetToken";
import Select from 'react-select';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ToastMsg from '../components/ToastMsg';
import { AppContext } from '../context/AppContext';

class OperatorAssign extends Component {
    static contextType = AppContext;
    state = {
        businessUnit: '',
        allBusinessUnits: [],
        projectsEquipmentList: [],
    }

    componentDidMount() {
        const { allBusinessUnits, ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllBu();
    }

    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.projectName, value: element.projectId }
                });
                this.setState({ allBusinessUnits: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getAllProjectEquipmentList(projectId) {
        fetch(process.env.REACT_APP_API_URL + "project_equipment_list/" + projectId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ projectsEquipmentList: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getOperatorName = (inputValue, callback) => {
        var url = '';

        if (!inputValue) {
            url = process.env.REACT_APP_API_URL + "search_operator";

        }
        else {
            url = process.env.REACT_APP_API_URL + "search_operator?q=" + inputValue;
        }

        setTimeout(() => {
            fetch(url, getGetRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    const tempArray = resp.data.map(function (element) {
                        element.label = `${element.name} (${element.phone})`;
                        element.value = element.id;
                        element.operatorId = element.id;
                        return element;
                    });
                    callback(tempArray);
                })
                .catch((error) => {
                    console.log(error, "catch the hoop")
                });
        });
    }

    handleBusinessUnitChange = (selectedOption) => {
        this.setState({ businessUnit: selectedOption });
        this.getAllProjectEquipmentList(selectedOption.value);
    }

    handleOperatorChange = (index) => (selectedOption) => {
        const tempArray = this.state.projectsEquipmentList;
        tempArray[index].operators = selectedOption;
        this.setState({ projectsEquipmentList: tempArray });
    }


    handleSubmit = (item) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to assign the operator to the selected equipment?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = {
                    equipmentId: item.equipmentId,
                    operators: item.operators
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                var apiEnd = "equipment_operator_assign";

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: resp.successMessage,
                            })
                            this.getAllProjectEquipmentList(this.state.businessUnit.value);
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }


    render() {
        return (<>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label"><p className="text-primary">Operator Assign </p></h3>
                    </div>
                </div>
                <div className="card-body">
                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label text-right">Business Unit:</label>
                        <div className="col-lg-4">
                            <Select
                                options={this.state.allBusinessUnits}
                                onChange={this.handleBusinessUnitChange}
                                value={this.state.businessUnit}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Equipment Code</th>
                                    <th>Equipment </th>
                                    <th>Operator</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.projectsEquipmentList.length > 0 ? this.state.projectsEquipmentList.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.equipmentCode}</td>
                                            <td>{item.equipmentName}</td>
                                            <td style={{ minWidth: '450px' }}>
                                                <AsyncSelect
                                                    value={item.operators}
                                                    defaultOptions
                                                    loadOptions={this.getOperatorName}
                                                    placeholder="Select Operator"
                                                    onChange={this.handleOperatorChange(index)}
                                                    isMulti
                                                />
                                            </td>
                                            <td>
                                                <button className="btn btn-primary" onClick={() => { this.handleSubmit(item) }}>Save</button>
                                            </td>
                                        </tr>
                                    )

                                })
                                    :
                                    <tr>
                                        <td colSpan="5" className="text-center">No data found</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        {/* <pre>{JSON.stringify(this.state.projectsEquipmentList, null, 2)}</pre> */}
                    </div>
                </div>
            </div>
        </>);
    }
}

export default OperatorAssign;