/* eslint-disable no-restricted-globals */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../_metronic/layout";

class Logout extends Component {
    getRootDomain() {
        var root_domain = "";
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.host.split(':')[0])) {
            return location.host.split(':')[0];
        } else if (location.host.indexOf('localhost') !== -1) {
            return 'localhost';
        } else {
            var temp = location.host.split('.').reverse();
            root_domain = '.' + temp[1] + '.' + temp[0];
            return root_domain;
        }
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    componentDidMount() {
        //this.Logout();
        document.cookie = 'MyToken=;path=/;domain=' + this.getRootDomain() + ';expires=' + new Date(0).toUTCString();
        window.location.href = process.env.REACT_APP_LOGIN + "login/index/" + encodeURIComponent(window.btoa(process.env.REACT_APP_URL));
    }

    // Logout() {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Content-Type': 'application/json' },
    //         body: JSON.stringify({ 'jwt': { token: this.getCookie('MyToken') } })
    //     };
    //     fetch(process.env.REACT_APP_LOGIN + "login/logout_view", requestOptions)
    //         .then((resp) => {
    //             return resp.json()
    //         })
    //         .then((resp) => {
    //             console.log(resp);

    //         })
    //         .catch((error) => {
    //             console.log(error, "catch the hoop")
    //         });
    // }

    render() {
        const jwt = this.getCookie('MyToken');
        return jwt.token ? <LayoutSplashScreen /> : <Redirect to="/" />;
    }
}

export default Logout;