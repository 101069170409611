import React, { Component } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { getGetRequestOptions } from "../components/GetToken";
import ToastMsg from '../components/ToastMsg';
import { AppContext } from '../context/AppContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

class CreditNote extends Component {
    static contextType = AppContext;
    state = {
        businessUnit: process.env.REACT_APP_EMD_BU_ID,
        billingBusinessUnit: "",
        billMonth: "",
        billYear: "",
        billNo: "",
        billId: "",
        remarks: "",
        refundedAmount: "",
        refundAmount: "",
        items: [],
        projectAccountant: "",
        allBusinessUnits: [],
        allBillNo: []
    }

    componentDidMount() {
        const { allBusinessUnits, ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllBu();
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };


    handleBuChange = businessUnit => {
        this.setState({ billingBusinessUnit: businessUnit }, () => {
            this.getBillNo();
        });
    }

    handleBillNoChange = bill => {

        this.setState({ billNo: bill }, () => {
            this.getBillDetails(bill.billId);
        });
    }

    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.projectName, value: element.projectId, isdisabled: element.projectName === 'EMD' ? false : true }
                });
                this.setState({ allBusinessUnits: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getBuBillNo = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        } else {
            const projectId = this.state.billingBusinessUnit.projectId;
            var url = process.env.REACT_APP_API_URL + "search_bill_no_by_bu/" + projectId + "?q=" + inputValue;
            setTimeout(() => {
                fetch(url, getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            return { ...element, label: element.billNo, value: element.billId }
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }


    getBillNo() {
        const jwt = this.context.jwt.token;
        const formData = {
            businessUnit: process.env.REACT_APP_EMD_BU_ID,
            billingBusinessUnit: this.state.billingBusinessUnit
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "get_bill_no_by_bu", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.map(function (element) {
                    return { ...element, label: element.billNo, value: element.billId }
                });
                this.setState({ allBillNo: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    getBillDetails(billId) {
        fetch(process.env.REACT_APP_API_URL + "bill_details/" + billId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    billId: billId,
                    billMonth: resp.data.details.billMonth,
                    billYear: resp.data.details.billYear,
                    billDate: resp.data.details.billDate,
                    total: Number(resp.data.details.total).toFixed(2),
                    billingBu: resp.data.details.billing_bu,
                    customer: resp.data.details.customer,
                    type: resp.data.details.type,
                    items: resp.data.details.bill_item,
                    billStatus: resp.data.details.bill_status,
                    status: resp.data.details.status,
                    category: resp.data.details.category,
                    refBillNo: resp.data.details.refBillNo,
                    refBillId: resp.data.details.refBillId,
                    amendmentDetails: resp.data.amendmentDetails,
                    refundedAmount: Number(resp.data.details.refundedAmount).toFixed(2)
                });

                this.setState({
                    totalRegularBillingAmount: (Number(resp.data.details.total) + Number(this.objPsum(resp.data.details.bill_item, 'deductionAmount'))).toFixed(2),
                    totalDeduction: (this.objPsum(resp.data.details.bill_item, 'deductionAmount')).toFixed(2)

                })

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    onDeductionAmountChange = index => evt => {
        const newItems = this.state.billItems.map(function (row, rindex) {
            if (index === rindex) {
                row.deductionAmount = evt.target.value;
                row.billingAmount = (Number(row.regularBillingAmount) - Number(row.deductionAmount)).toFixed(2);
            }
            return row;
        })
        this.setState({ billItems: newItems });
        this.calcTotal();
    }

    onRegularBillingAmountChange = index => evt => {
        const newItems = this.state.billItems.map(function (row, rindex) {
            if (index === rindex) {
                row.regularBillingAmount = evt.target.value;
                row.billingAmount = (Number(row.regularBillingAmount) - Number(row.deductionAmount)).toFixed(2);
            }
            return row;
        })

        this.setState({ billItems: newItems });
        this.calcTotal();
    }

    objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }
    calcTotal = () => {
        this.setState({
            totalRegularBillingAmount: (this.objPsum(this.state.billItems, 'regularBillingAmount')).toFixed(2),
            totalDeduction: (this.objPsum(this.state.billItems, 'deductionAmount')).toFixed(2),
            totalBillingAmount: (this.objPsum(this.state.billItems, 'billingAmount')).toFixed(2)
        })
    }

    handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create this credit note!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = {
                    businessUnit: process.env.REACT_APP_EMD_BU_ID,
                    billingBusinessUnit: this.state.billingBusinessUnit,
                    cnDate: this.state.cnDate,
                    refundedAmount: this.state.refundedAmount,
                    refundAmount: this.state.refundAmount,
                    billAmount: this.state.total,
                    billMonth: this.state.billMonth,
                    billYear: this.state.billYear,
                    billItems: this.state.billItems,
                    billNo: this.state.billNo,
                    billId: this.state.billId,
                    remarks: this.state.remarks
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                var apiEnd = "credit_note_create";

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {
                            let successMsg = [`Credit Note NO# ${resp.data.no}`];

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })

                            this.setState(this.baseState);
                            this.props.history.push(`/generate-bill-list-credit-note-details/${resp.data.id}`);
                        }
                        else {
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                errorsMessage.push("Something went wrong");
                            }
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }


    render() {
        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label"><p className="text-primary">Credit Note</p></h3>
                        </div>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Business Unit</label>
                                    <div className="col-lg-6">
                                        <select className="form-control form-control-sm" id="businessUnit" name="businessUnit" value={this.state.businessUnit} onChange={this.handleInputOnChange} disabled>
                                            <option value="">Select </option>
                                            {this.state.allBusinessUnits.map(item =>
                                                <option key={item.projectId} value={item.projectId}>{item.projectName}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="cnDate" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Credit Note Date<span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <input type="date" name="cnDate" id="cnDate" value={this.state.cnDate} onChange={this.handleInputOnChange} className="form-control form-control-md" />

                                    </div>
                                </div>



                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Bill To<span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <Select
                                            value={this.state.billingBusinessUnit}
                                            onChange={this.handleBuChange}
                                            options={this.state.allBusinessUnits}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>
                                {/* <div className="form-group row">
                                    <label htmlFor="billNo" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Bill No</label>
                                    <div className="col-lg-6">
                                    <Select
											value={this.state.billNo}
											onChange={this.handleBillNoChange}
											options={this.state.allBillNo}
										/>
                                    </div>
                                </div> */}

                                <div className="form-group row">
                                    <label htmlFor="billNo" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Bill No</label>
                                    <div className="col-lg-6">
                                        <AsyncSelect
                                            value={this.state.billNo}
                                            defaultOptions
                                            loadOptions={this.getBuBillNo}
                                            placeholder="Search Bill"
                                            onChange={this.handleBillNoChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="billMonth" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Bill  Month</label>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <input type="text" name="billMonth" id="billMonth" value={this.state.billMonth} className="form-control form-control-md" disabled />
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="form-group row">
                                                    <label htmlFor="billYear" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Year</label>
                                                    <div className="col-lg-8">
                                                        <input type="text" name="billMonth" id="billMonth" value={this.state.billYear} className="form-control form-control-md" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>

                        </div>

                        {this.state.items.length > 0 &&
                            <div className="card-body">
                                <div className="row">
                                    <div className="table-responsive" style={{ height: '345px', marginBottom: '5px' }}>
                                        <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                            <tbody>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Equipment Code</th>
                                                    <th>Equipment </th>
                                                    <th>Capacity</th>
                                                    <th>Manufacturer</th>
                                                    <th>Bill periods</th>
                                                    <th>Regular Billing Amount</th>
                                                    <th>Deduction Amount</th>
                                                    <th>Bill  Amount</th>
                                                </tr>
                                                {this.state.items.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.equipment.equipmentCode}</td>
                                                        <td>{item.equipment.equipmentName}</td>
                                                        <td>{item.equipment.capacity} </td>
                                                        <td>{item.equipment.manufacturerName}</td>
                                                        <td>{item.billPeriodMonth > 0 ? item.billPeriodMonth + ' Month' : item.billPeriodDay + ' Days'}</td>
                                                        <td>{Number(item.amount) + Number(item.deductionAmount)}</td>
                                                        <td>{item.deductionAmount}</td>
                                                        <td>{item.amount}</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="6" className="text-right">Total </td>
                                                    <td>{this.state.totalRegularBillingAmount}</td>
                                                    <td>{this.state.totalDeduction}</td>
                                                    <td>{this.state.total}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>

                                <div className="row justify-content-end">

                                    <div className="col-6 card card-body bg-light">
                                        <table className="table table-bordered table-hover ">
                                            <tbody>
                                                <tr>
                                                    <th>Total Bill Amount</th>
                                                    <td>{this.state.total}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refunded  Amount</th>
                                                    <td>{this.state.refundedAmount}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refund Amount<span className="required text-danger"> *</span></th>
                                                    <td><input className="form-control form-control-sm col-md-6" type="text" name="refundAmount" value={this.state.refundAmount} onChange={this.handleInputOnChange} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Remarks<span className="required text-danger"> *</span></th>
                                                    <td><textarea className="form-control form-control-sm col-md-8" name="remarks" id="remarks" value={this.state.remarks} onChange={this.handleInputOnChange} /></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                            </div>
                        }
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-4">
                                </div>
                                <div className="col-6">
                                    <button type="submit" className="btn btn-success mr-2">Submit</button>
                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                        {/* <pre>
                            {JSON.stringify(this.state, null, 2)}
                        </pre> */}
                    </form>


                </div >
                <ToastContainer />
            </>

        );
    }
}

export default CreditNote;