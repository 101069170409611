import React, { Component } from 'react';
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import { getGetRequestOptions } from "../components/GetToken";
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import InvItem from '../components/InvItemDt';
import { AppContext } from '../context/AppContext';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	menu: (provided) => ({
		...provided,
		whiteSpace: 'nowrap',
		width: 'max-content',
		backgroundColor: '#ffffff'
	}),
	valueContainer: (provided) => ({
		...provided,
		minWidth: "200px"
	})
};

class TagMoInvItem extends Component {
	static contextType = AppContext;
	state = {
		maintOrderId: "",
		details: {
			equipment: { equipmentName: '' },
			team: { teamName: '' },
			maintenancetype: "",
			priority: "",
			equipmentCondition: "",
			responsibleperson: [],
			businessunit: { businessunitName: '' },
			maint_businessunit: { maintBusinessunitName: '' },
			parts: [],
			operations: [],
			maintenance_status: [],
		},
		partsIndex: "",
		warehouseId: "",
		selectedItem: "",
		warehouseOptions: [],
		totalPartsCost: 0,
		totalOperationsCost: 0,
		totalCost: 0
	}

	componentDidMount() {
		const {
			params: { id }
		} = this.props.match;

		this.setState({ maintOrderId: this.props.match.params.id });
		this.getMaintenanceOrderDetails(id);
	}

	getMaintenanceOrderDetails(maintOrderId) {
		fetch(process.env.REACT_APP_API_URL + "maintenance_order/maintenance_order_info/" + maintOrderId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				this.setState({ details: resp }, () => { this.getBuWarehouse(resp.business_unit_id); this.calcTotal() });
				this.state.details.parts.map((row, rindex) =>
					this.getItemStock(row.item_id, row.warehouse, rindex)
				);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	fetchItemData = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "inv_item_search";
		} else {
			api = process.env.REACT_APP_API_URL + "inv_item_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getGetRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	getBuWarehouse = (businessUnitId) => {
		fetch(process.env.REACT_APP_API_URL + "get_warehouse_bu_wise/" + businessUnitId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				this.setState({ warehouseOptions: resp.data });
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}


	getItemStock = (itemId, warehouseId, index) => {
		fetch(process.env.REACT_APP_API_URL + "item/item_stock_warehouse_wise/" + itemId + "/" + warehouseId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const newItems = this.state.details.parts.map(function (row, rindex) {
					if (index !== rindex) return row;
					return { ...row, stock: resp.data }
				});
				this.setState({ details: { ...this.state.details, parts: newItems } });

			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	getItemIssuePrice = (itemId, unitId, warehouseId, index) => {
		fetch(process.env.REACT_APP_API_URL + "item/item_issue_price/" + itemId + "/" + unitId + "/" + warehouseId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const newItems = this.state.details.parts.map(function (row, rindex) {
					if (index !== rindex) return row;
					return { ...row, price: resp.data }
				});
				this.setState({ details: { ...this.state.details, parts: newItems } }, () => { this.calcTotal() });

			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	onPartsItemChange = index => item => {
		this.getItemStock(item.itemId, this.state.details.parts[index].warehouse, index);
		this.getItemIssuePrice(item.itemId, item.unitId, this.state.details.parts[index].warehouse, index);
		const newItems = this.state.details.parts.map(function (row, rindex) {
			if (index !== rindex) return row;
			return { ...row, item: item, unit: item.unitName }
		});
		this.setState({ details: { ...this.state.details, parts: newItems } });
	}

	onWarehouseChange = (index) => evt => {
		const newItems = this.state.details.parts.map(function (row, rindex) {
			if (index !== rindex) {
				return row;
			}
			else {
				return { ...row, warehouse: evt.target.value }
			}
		});
		if (this.state.details.parts[index].item) {
			this.setState({ details: { ...this.state.details, parts: newItems } }, () => { this.getItemStock(this.state.details.parts[index].item.itemId, this.state.details.parts[index].warehouse, index); this.calcTotal() });
		}
		else {
			this.setState({ details: { ...this.state.details, parts: newItems } }, () => this.calcTotal());
		}
	}

	confirmInvItem = (index, warehouseId, selectedItem) => {
		this.setState({ invItemModalShow: true, partsIndex: index, warehouseId: warehouseId, selectedItem: selectedItem !== "" ? selectedItem.itemId : "" });
	}

	onInvItemConfirm = (confirmData) => {
		this.setState({ invItemModalShow: false });
	}

	onInvItemSelected = (item) => {
		var index = this.state.partsIndex;
		const newItems = this.state.details.parts.map(function (row, rindex) {
			if (index === rindex) {
				var invItem = {
					itemName: item.itemName,
					itemId: item.itemId,
					itemCode: item.itemCode,
					specificationName: item.specificationName,
					sizeValue: item.sizeValue,
					unitName: item.unitName,
					label: item.label,
					value: item.value
				}
				row.item = invItem;
				row.unit = item.unitName;
			}
			return row;
		})
		this.setState({ details: { ...this.state.details, parts: newItems } },
			() => {
				this.getItemStock(item.itemId, this.state.details.parts[index].warehouse, index);
				this.getItemIssuePrice(item.itemId, item.unitId, this.state.details.parts[index].warehouse, index)
			}
		);
	}

	objPsum = (items, prop) => {
		return items.reduce(function (a, b) {
			return Number(a) + Number(b[prop]);
		}, 0);

	}
	calcTotal = () => {
		var totalPartsCost = 0;
		this.state.details.parts.forEach(function (item) {
			totalPartsCost += Number(item.price) * Number(item.quantity);
		});
		this.setState({ totalPartsCost: (totalPartsCost).toFixed(2) });
		const totalOperationsCost = this.objPsum(this.state.details.operations, 'amount');
		this.setState({ totalOperationsCost: (totalOperationsCost).toFixed(2) });
		const totalCost = Number(totalPartsCost) + Number(totalOperationsCost);
		this.setState({ totalCost: (totalCost).toFixed(2) });
	}

	handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to tag inv item in this Maintenance Order!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const jwt = this.context.jwt.token;
				const formData = {
					maintOrderId: this.state.maintOrderId,
					parts: this.state.details.parts
				};
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
					body: JSON.stringify(formData)
				};

				var apiEnd = "maintenance_order/tag_inv_item/" + this.state.maintOrderId;

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp);

						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: 'Maintenance Order inv item tagged successfully!',
							})

							this.setState(this.baseState);
							this.props.history.push(`/maintenance-order-details/${resp.data.orderId}`);
						}
						else {
							var errorsMessage = [];

							if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
								var errorsObj = resp.errorMessage;
								Object.keys(errorsObj).forEach(function (value) {
									errorsObj[value].forEach(function (v) {
										errorsMessage.push(v)
									});

								});

							} else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
								errorsMessage.push(resp.errorMessage);
							} else {

								errorsMessage.push("Something went wrong");
							}
							Swal.fire({
								icon: 'error',
								title: resp.heading,
								text: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}


	render() {
		const resListItem = this.state.details.responsibleperson.map((person, key) =>
			<li key={key} >{`${person.employeeName} ${person.employeeCode}`}</li>
		);

		let invItemModalClose = () => {
			this.setState({ invItemModalShow: false });
		};

		return (
			<>
				<div className="card card-custom">
					<div className="card-header">
						<div className="card-title">
							<h3 className="card-label">
								Maintenance Order  #{this.state.details.orderNo}
							</h3>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="card-body">
							<div className="row">
								<div className="col-6">
									<table className="table table-bordered table-hover">
										<tbody>
											<tr>
												<th>Equipment</th>
												<td>{this.state.details.equipment.equipmentName}</td>
											</tr>
											<tr>
												<th>Equipment Code</th>
												<td>{this.state.details.equipment.equipmentCode}</td>
											</tr>
											<tr>
												<th>Maintenance Order No</th>
												<td>{this.state.details.orderNo}</td>
											</tr>
											<tr>
												<th>Order Date</th>
												<td>{this.state.details.orderDate}</td>
											</tr>
											<tr>
												<th>Title</th>
												<td>{this.state.details.title}</td>
											</tr>
											<tr>
												<th>Maintenance Type</th>
												<td>{this.state.details.maintenancetype != null ? this.state.details.maintenancetype.name : ''}</td>
											</tr>
											{this.state.details.completeDate != null &&
												<tr>
													<th>Complete Date</th>
													<td>{this.state.details.completeDate}</td>
												</tr>
											}
										</tbody>
									</table>
								</div>

								<div className="col-6">
									<table className="table table-bordered table-hover">
										<tbody>
											<tr>
												<th>Equipment Condition</th>
												<td>{this.state.details.equipmentCondition}</td>
											</tr>
											<tr>
												<th>Priority</th>
												<td>{this.state.details.priority === "high" && "High"}{this.state.details.priority === "medium" && "Medium"}{this.state.details.priority === "low" && "Low"}</td>
											</tr>
											<tr>
												<th>Responsible Person</th>
												<td><ul>{resListItem}</ul></td>
											</tr>
											<tr>
												<th> Equipment Location BU</th>
												<td>{this.state.details.maint_businessunit != null ? this.state.details.maint_businessunit.maintBusinessunitName : ''}</td>
											</tr>
											<tr>
												<th>Closing Hrs Meter</th>
												<td>{this.state.details.closingHrsMeter}</td>
											</tr>
											<tr>
												<th>Category</th>
												<td>{this.state.details.category === "breakdown_maintenance" ? "Breakdown Maintenance" : "Schedule Maintenance"}</td>
											</tr>
											<tr>
												<th>Status</th>
												<td>
													{this.state.details.status === 'created' && <span className='label label-lg label-primary label-inline mr-2'>Created</span>}
													{this.state.details.status === 'approved' && <span className='label label-lg label-info label-inline mr-2'>Approved</span>}
													{this.state.details.status === 'denied' && <span className='label label-lg label-danger label-inline mr-2'>Denied</span>}
													{this.state.details.status === 'completed' && <span className='label label-lg label-success label-inline mr-2'>Completed</span>}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div className="col-12 mt-10">
								<Tabs defaultActiveKey="parts" id="uncontrolled-tab-example">
									<Tab eventKey="parts" title="Parts">
										<div className="table-responsive">
											<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
												<thead>
													<tr>
														<th>SL</th>
														<th>Warehouse</th>
														<th>Material Name</th>
														<th>Material Desc</th>
														<th>Inv Item</th>
														<th>Unit</th>
														<th>Stock</th>
														<th>Price</th>
														<th>Quantity</th>
														<th>Inv Status</th>
														<th>Cost</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.details.parts.map((item, index) =>
															<tr key={index}>
																<td>{index + 1}</td>
																<td><select className="form-control" onChange={this.onWarehouseChange(index)} value={item.warehouse} disabled={item.invStatus === 'booked' || item.invStatus === 'issued'}>
																	<option value="">Select Warehouse</option>
																	{
																		this.state.warehouseOptions.map(function (item, id) {
																			return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
																		})
																	}
																</select>
																</td>
																<td>{item.materialName}</td>
																<td>{item.materialDesc}</td>
																<td>
																	<div className='input-group'>
																		<AsyncSelect
																			key={index}
																			value={item.item}
																			defaultOptions
																			// loadOptions={this.fetchItemData.bind(null, item.categoryId)}
																			loadOptions={this.fetchItemData}
																			placeholder="Select Item"
																			onChange={this.onPartsItemChange(index)}
																			styles={customStylesSelect}
																			menuPosition="fixed"
																			isDisabled={item.invStatus === 'issued'}
																		/>
																		<div className="input-group-append" style={{ marginTop: "-2px", zIndex: '0' }}>
																			<button className="btn btn-primary" type="button" onClick={() => this.confirmInvItem(index, item.warehouse, item.item ? item.item : "")} disabled={item.warehouse === "" || item.invStatus === "issued"}><i className="flaticon2-search-1"></i></button>
																		</div>
																	</div>
																</td>
																<td>{item.unit}</td>
																<td>{item.stock}</td>
																<td>{item.price} </td>
																<td>{item.quantity}</td>
																<td>
																	{item.invStatus === "booked" && <span className={'label label-lg label-primary label-inline mr-2'}>Booked</span>}
																	{item.invStatus === "issued" && <span className={'label label-lg label-success label-inline mr-2'}>Issued</span>}
																</td>
																<td>{(Number(item.price) * Number(item.quantity)).toFixed(2)}</td>
															</tr>

														)
													}

												</tbody>
												<tfoot>
													<tr>
														<td colSpan="10" style={{ "textAlign": "right" }}>
															Total
														</td>
														<td colSpan="2">{this.state.totalPartsCost}</td>
													</tr>
												</tfoot>
											</table>
										</div>
									</Tab>

									<Tab eventKey="operations" title="Operations" >
										<div className="table-responsive">
											<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
												<thead>
													<tr>
														<th>SL</th>
														<th>Operation</th>
														<th>Operation Short Desc</th>
														<th>Work Start Date</th>
														<th>Work Finish Date</th>
														<th>Duration(h:m)</th>
														<th>Has SRC</th>
														<th>SRC ID</th>
														<th>Amount</th>
														<th>SRC Confirm </th>
														<td>Completed</td>
													</tr>
												</thead>
												<tbody>
													{
														this.state.details.operations.map((operation, index) =>

															<tr key={index}>
																<td>{index + 1}</td>
																<td>{operation.operationTitle}</td>
																<td>{operation.operationDetails}</td>
																<td>{operation.startDate}</td>
																<td>{operation.endDate}</td>
																<td>
																	{operation.duration.split(":")[0] + ':' + operation.duration.split(":")[1]}
																</td>
																<td>{operation.hasSrr === "yes" ? "Yes" : "No"}</td>
																<td>{operation.srrNo}</td>
																<td>{operation.amount}</td>
																{operation.isSrrConfirm === "yes" && <td>Yes</td>}
																{operation.isSrrConfirm === "no" && <td>No</td>}
																{operation.isSrrConfirm === null && <td></td>}
																<td><span className={operation.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{operation.isComplete === 'no' ? 'No' : 'Yes'}</span></td>

															</tr>

														)
													}

												</tbody>
												<tfoot>
													<tr>
														<td colSpan="8" style={{ "textAlign": "right" }}>
															Total
														</td>
														<td colSpan="3">{this.state.totalOperationsCost}</td>
													</tr>
												</tfoot>
											</table>
										</div>
									</Tab>
								</Tabs>

								<div className="col-md-4 offset-md-8 mt-10">
									<div className="row">
										<div className="col-md-6"><b>Total Parts Cost</b></div>
										<div className="col-md-3">{this.state.totalPartsCost}</div>
									</div>
									<div className="row">
										<div className="col-md-6"><b>Total Operations Cost</b></div>
										<div className="col-md-3">{this.state.totalOperationsCost}</div>
									</div>
									<div className="row">
										<div className="col-md-6"><b>Total Cost</b></div>
										<div className="col-md-3">{this.state.totalCost}</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card-footer">
							<div className="row">
								<div className="col-4">
								</div>
								<div className="col-6">
									<button type="submit" className="btn btn-success mr-2">Submit</button>
									<button type="reset" className="btn btn-secondary">Cancel</button>
								</div>
							</div>
						</div>

						<Modal size="xl" show={this.state.invItemModalShow} onHide={invItemModalClose} aria-labelledby="example-modal-sizes-title-lg">
							<Modal.Body>
								<InvItem onInvItemConfirm={this.onInvItemConfirm} onInvItemSelected={this.onInvItemSelected} warehouseId={this.state.warehouseId} selectedItem={this.state.selectedItem} />
							</Modal.Body>

							<Modal.Footer>
								<Button variant="primary" onClick={invItemModalClose}>Ok</Button>
								<Button variant="secondary" onClick={invItemModalClose}>Cancel</Button>
							</Modal.Footer>
						</Modal>

					</form>
					{/* <pre>
                    {JSON.stringify(this.state, null, 2)}
                </pre> */}
				</div>
				{/* <ToastContainer /> */}
			</>

		);
	}
}

export default TagMoInvItem;