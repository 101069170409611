import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import { getGetRequestOptions } from "../components/GetToken";
import { AppContext } from '../context/AppContext';
import Select, { components } from 'react-select';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

const UserSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
        <span>{props.data.employeeName}</span>
    </components.SingleValue>
)

class EquipmentProjectInChargeAssign extends Component {
    static contextType = AppContext;
    state = {
        businessUnit: process.env.REACT_APP_EMD_BU_ID,
        equipment: "",
        project: "",
        projectInCharge: "",
        remarks: "",
        allBusinessUnits: [],
    }

    componentDidMount() {
        const { ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllBu();
    }

    getEquipment = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        } else {
            const projectId = this.state.businessUnit;
            var url = process.env.REACT_APP_API_URL + "equipment/equipment_search/" + projectId + "?q=" + inputValue;
            setTimeout(() => {
                fetch(url,
                    getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.equipmentName} (${element.equipmentCode})`;
                            element.value = element.equipmentId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.projectName, value: element.projectId }
                });
                this.setState({ allBusinessUnits: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getAllProjectInCharge(buId, inputValue, callback) {
        if (!inputValue) {
            callback([]);

        } else {
            var api = process.env.REACT_APP_API_URL + "users/project_in_charge?q=" + inputValue + "&bu=" + buId;
            setTimeout(() => {
                fetch(api, getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            return { ...element, id: element.employeeId, label: element.employeeName, value: element.employeeId }
                        });

                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }


    handleEquipmentChange = item => {
        this.setState({ equipment: item });
    }

    handleProjectChange = project => {
        this.setState({ project: project, projectInCharge: "" });
    }

    handleProjectInChargeChange = projectInCharge => {
        this.setState({ projectInCharge: projectInCharge });
    }

    handleInputOnChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit = evt => {
        evt.preventDefault();
        const isValid = true
        if (isValid) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to assign",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    const jwt = this.context.jwt.token;
                    const formData = {
                        businessUnit: process.env.REACT_APP_EMD_BU_ID,
                        equipment: this.state.equipment,
                        project: this.state.project,
                        projectInCharge: this.state.projectInCharge,
                        remarks: this.state.remarks,
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                        body: JSON.stringify(formData)
                    };

                    var apiEnd = "equipment/assign_project_in_charge";

                    fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                        .then((resp) => {
                            return resp.json()
                        })
                        .then((resp) => {
                            if (resp.success === true) {
                                let successMsg = resp.successMessage;
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: successMsg,
                                })

                                this.setState({
                                    equipment: "",
                                    project: "",
                                    projectInCharge: "",
                                    remarks: ""
                                })

                            }
                            else {
                                //var errorsMessage = "";
                                var errorsMessage = [];

                                if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                    var errorsObj = resp.errorMessage;
                                    Object.keys(errorsObj).forEach(function (value) {
                                        errorsObj[value].forEach(function (v) {
                                            //errorsMessage.push(v)
                                            errorsMessage += '<div>' + v + '</div>';
                                        });

                                    });

                                } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                    //errorsMessage = resp.errorMessage;
                                    errorsMessage.push(resp.errorMessage);
                                } else {
                                    //errorsMessage = "Something went wrong";
                                    errorsMessage.push("Something went wrong");
                                }
                                //console.log(errorsMessage);
                                Swal.fire({
                                    icon: 'error',
                                    title: resp.heading,
                                    html: errorsMessage
                                })
                            }


                        })
                        .catch((error) => {
                            console.log(error, "catch the hoop")
                        });
                }
            })
        }
    }

    render() {
        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            <p className="text-primary">
                                Project In-charge Assign
                            </p>
                        </h3>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Business Unit <span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <Select
                                            value={this.state.project}
                                            onChange={this.handleProjectChange}
                                            options={this.state.allBusinessUnits}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-lg-2 col-form-label" >Project In-charge <span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <AsyncSelect
                                            value={this.state.projectInCharge}
                                            defaultOptions
                                            // styles={customStylesInChargeSelect}
                                            loadOptions={this.getAllProjectInCharge.bind(null, this.state.project.projectId)}
                                            placeholder="Select Project In-charge"
                                            onChange={this.handleProjectInChargeChange}
                                            menuPosition="fixed"
                                            isDisabled={this.state.project === "" ? true : false}
                                            filterOptions={option => option}
                                            components={{ SingleValue: UserSingleValue }}
                                            openMenuOnClick={true}
                                            getOptionLabel={(option) => (
                                                <>
                                                    <div className='row'>
                                                        <div>
                                                            {option.avatar !== "" ?
                                                                <img src={`${process.env.REACT_APP_USER_ASSET}${option.fileName}`} className='img-fluid' alt='Approver' style={{ width: "70px" }} />
                                                                :
                                                                <img src={toAbsoluteUrl("/media/users/blank.png")} className='img-fluid' alt='Approver' style={{ width: "70px" }} />
                                                            }
                                                        </div>
                                                        <div style={{ marginLeft: "5px" }}>
                                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
                                                                <span> Name: {option.employeeName} </span><br></br>
                                                            </span>
                                                            <span className="svg-icon svg-icon-md svg-icon-danger">
                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
                                                                <span>Designation: {option.designationTitle} </span><br></br>
                                                            </span>
                                                            <span className="svg-icon svg-icon-md svg-icon-warning">
                                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Color-profile.svg")} />
                                                                <span> Work Station: {option.workStationName}</span>
                                                            </span>
                                                        </div>

                                                    </div>

                                                </>
                                            )}

                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="equipment" className="col-lg-2 col-form-label">Equipment <span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <AsyncSelect
                                            value={this.state.equipment}
                                            defaultOptions
                                            loadOptions={this.getEquipment}
                                            placeholder="Select Equipment"
                                            onChange={this.handleEquipmentChange}
                                            isMulti
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="remarks" className="col-lg-2 col-form-label">Remarks</label>
                                    <div className="col-lg-6">
                                        <textarea className="form-control" id="remarks" name="remarks" value={this.state.remarks} onChange={this.handleInputOnChange} />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-4">
                                </div>
                                <div className="col-6">
                                    <button type="submit" className="btn btn-success mr-2">Submit</button>
                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>

                        {/* <pre>
							{JSON.stringify(this.state, null, 2)}
						</pre> */}
                    </form>

                </div>

                {/* <ToastContainer /> */}
            </>
        );
    }
}

export default EquipmentProjectInChargeAssign;