import React, { Component } from 'react';
import MakeCreate from '../components/MakeCreate';
import DataTable from  '../components/DataTable'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
// import MakeDt from '../components/MakeDt';

const columns = [
    { 'value': 'id', 'title': 'Id' },
    { 'value': 'name', 'title': 'Name' },
    {'value': 'action',title: 'Action',actions: [
        {link: 'edit', calAction: true}
      ]
    }
 ];

 const filters = {
    'id': {
        filterType: 'like',
        filterValue: ''
    },
    'name': {
        filterType: 'like',
        filterValue: ''
    }
  };

const url = process.env.REACT_APP_API_URL + "make_dt";


class Make extends Component {

    constructor(props) {
        super(props);
        this.state = { newMake: "", name: "" }
    }
    onCreate = ({id,name}) => {
        this.setState({ newMake: { id: id, name: name } });
    }
    onAction = ({id, name}) => {
        this.setState({ id: id, name: name });
    }
    render() {
        return (
            <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title"><p className="text-primary">Make Setup</p></h3>
                </div>

                <div className="card-body">
                    <MakeCreate onCreate={this.onCreate} id={this.state.id} name={this.state.name} />
                </div>

                <div className="card-header">
                    <h3 className="card-title"><p className="text-primary">Make List</p></h3>
                </div>
                <div className="card-body">
                 <DataTable url={url} columns={columns} filters={filters}  newData={this.state.newMake} onAction={this.onAction}>
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                    />
                    </span>
                 </DataTable>   
                    {/* <MakeDt addnewMake={this.state.newMake} onAction={this.onAction} /> */}

                </div>

            </div >

        );
    }
}

export default Make;