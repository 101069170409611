import React, { Component } from 'react';
import { Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { AppContext } from '../context/AppContext';

const columns = [
    { 'value': 'action', 'title': 'Action' },
    { 'value': 'itemId', 'title': 'Id' },
    { 'value': 'itemName', 'title': 'Item' },
    { 'value': 'itemCode', 'title': 'Inv Code' },
    { 'value': 'stockQty', 'title': 'Stock Qty' },
    { 'value': 'specificationName', 'title': 'Specifcation' },
    { 'value': 'sizeValue', 'title': 'Size' },
    { 'value': 'brandName', 'title': 'Brand' },
    { 'value': 'modelName', 'title': 'Model' },
    { 'value': 'unitName', 'title': 'Unit' }

];

const url = process.env.REACT_APP_API_URL + "item/inv_item_dt";

class InvItemDt extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            warehouseId: this.props.warehouseId,
            selectedItem: this.props.selectedItem,
            entities: {
                data: [],
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 20,
                to: 1,
                total: 1,
            },
            first_page: 1,
            current_page: 1,
            sorted_column: columns[1].value,
            offset: 4,
            order: 'DESC',
            limit: '50',
            orderBy: 'itemId',
            orderType: 'ASC',
            globalSearch: '',
            columns: {
                'itemId': {
                    filterType: 'like',
                    filterValue: ''
                },
                'itemName': {
                    filterType: 'like',
                    filterValue: ''
                },
                'itemCode': {
                    filterType: 'like',
                    filterValue: ''
                },
                'stockQty': {
                    filterType: 'like',
                    filterValue: ''
                },
                'specificationName': {
                    filterType: 'like',
                    filterValue: ''
                },
                'sizeValue': {
                    filterType: 'like',
                    filterValue: ''
                },
                'brandName': {
                    filterType: 'like',
                    filterValue: ''
                },
                'modelName': {
                    filterType: 'like',
                    filterValue: ''
                },
                'unitName': {
                    filterType: 'like',
                    filterValue: ''
                }

            }
        };
    }


    fetchEntities() {
        var selectedItem = this.state.selectedItem;
        this.setState({ loading: true });
        let fetchUrl = `${url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&globalSearch=${this.state.globalSearch}&per_page=${this.state.entities.per_page}`;
        const jwt = this.context.jwt.token;

        fetch(fetchUrl, {
            method: "POST",
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
            body: JSON.stringify(this.state)
        })
            .then((resp) => {
                return resp.json()
            })
            .then((response) => {
                const newItems = response.data.data.data.map(function (row) {
                    if (row.itemId === selectedItem) {
                        row.isSelected = 1;
                    }
                    else {
                        row.isSelected = 0;
                    }
                    return row
                })
                this.setState({
                    entities: {
                        current_page: response.data.data.current_page,
                        data: newItems,
                        first_page_url: response.data.data.first_page_url,
                        from: response.data.data.from,
                        last_page: response.data.data.last_page,
                        last_page_url: response.data.data.last_page_url,
                        next_page_url: response.data.data.next_page_url,
                        path: response.data.data.path,
                        per_page: response.data.data.per_page,
                        prev_page_url: response.data.data.prev_page_url,
                        to: response.data.data.to,
                        total: response.data.data.total
                    },
                    loading: false
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error, "catch the hoop")
            });

    }

    changePage(pageNumber) {
        this.setState({ current_page: pageNumber }, () => { this.fetchEntities() });
    }

    changePerPage = evt => {

        this.setState({ entities: { ...this.state.entities, per_page: evt.target.value } }, () => { this.fetchEntities() });
    }

    changeGlobalSearch = evt => {
        this.setState({ globalSearch: evt.target.value }, () => { this.fetchEntities() });
    }

    columnsFilterChange = evt => {
        this.setState({
            columns: {
                ...this.state.columns, [evt.target.name]: {
                    filterType: 'like',
                    filterValue: evt.target.value
                },
            }
        }, () => { this.fetchEntities() });
    }


    columnHead(value) {
        return value.split('_').join(' ');
    }

    pagesNumbers() {
        if (!this.state.entities.to) {
            return [];
        }
        let from = this.state.entities.current_page - this.state.offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + (this.state.offset * 2);
        if (to >= this.state.entities.last_page) {
            to = this.state.entities.last_page;
        }
        let pagesArray = [];

        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }

    componentDidMount() {
        this.setState({ current_page: this.state.entities.current_page, loading: true }, () => { this.fetchEntities() });
    }

    tableHeads() {
        let icon;
        if (this.state.order === 'asc') {
            icon = <i className="fa fa-arrow-up"></i>;
        } else {
            icon = <i className="fa fa-arrow-down"></i>;
        }
        return columns.map((column, index) => {
            if (column.value === 'action') {
                return <th style={{ "textAlign": "center" }} key={index}>
                    <p className="text-primary"> {column.title}</p>
                </th>
            }
            else {
                return <th style={{ "textAlign": "center" }} key={index} onClick={() => this.sortByColumn(column.value)}>
                    <p className="text-primary"> {this.columnHead(column.title)}{column.value === this.state.sorted_column && icon}</p>
                </th>
            }

        });
    }

    tableHeadFilter() {

        return columns.map((column, index) => {
            if (column.value === 'action') {
                return <td key={index}></td>
            }
            else {
                return <td key={index} >
                    <input type="text" name={column.value} value={this.state.columns[column.value].filterValue} onChange={this.columnsFilterChange} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
                </td>
            }
        });

    }


    dataList() {
        if (this.state.entities.data.length) {
            return this.state.entities.data.map((value, key) => {
                return <tr key={key} >
                    <td style={{ padding: '0.75rem', textAlign: 'center' }} onClick={() => this.onSelectChange(key, value)}>
                        {value.isSelected === 1 && (
                            <span className='svg-icon svg-icon-3x svg-icon-success'>
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Navigation/Check.svg'
                                    )}
                                />
                            </span>
                        )}

                        {value.isSelected === 0 && (
                            <span className='svg-icon svg-icon-3x svg-icon-danger'>
                                <SVG
                                    src={toAbsoluteUrl(
                                        '/media/svg/icons/Navigation/Close.svg'
                                    )}
                                />
                            </span>
                        )}
                    </td>
                    <td className="text-center">{value.itemId}</td>
                    <td className="text-center">{value.itemName}</td>
                    <td className="text-center">{value.itemCode}</td>
                    <td className="text-center">{value.stockQty}</td>
                    <td className="text-center">{value.specificationName}</td>
                    <td className="text-center">{value.sizeValue}</td>
                    <td className="text-center">{value.brandName}</td>
                    <td className="text-center">{value.modelName}</td>
                    <td className="text-center">{value.unitName}</td>
                </tr>
            })
        } else {
            return <tr>
                <td colSpan={columns.length} className="text-center">No Records Found.</td>
            </tr>
        }
    }


    sortByColumn(column) {
        if (column === this.state.sorted_column) {
            this.state.order === 'asc' ? this.setState({ order: 'desc', current_page: this.state.first_page }, () => { this.fetchEntities() }) : this.setState({ order: 'asc' }, () => { this.fetchEntities() });
        } else {
            this.setState({ sorted_column: column, order: 'asc', current_page: this.state.first_page }, () => { this.fetchEntities() });
        }
    }

    pageList() {
        return this.pagesNumbers().map(page => {
            return <li className={page === this.state.entities.current_page ? 'page-item active' : 'page-item'} key={page}>
                <button className="page-link" onClick={() => this.changePage(page)}>{page}</button>
            </li>
        })
    }

    onSelectChange = (index, value) => {
        const newItems = this.state.entities.data.map(function (row, rindex) {
            if (value.isSelected === 0) {
                if (index === rindex) {
                    if (row.isSelected === 0) {
                        row.isSelected = 1;
                    }
                    else {
                        row.isSelected = 0;
                    }
                } else {
                    row.isSelected = 0;
                }
            } else {
                row.isSelected = 0;
            }
            return row
        })
        this.setState({
            entities: {
                data: newItems,
                current_page: this.state.entities.current_page,
                first_page_url: this.state.entities.first_page_url,
                from: this.state.entities.from,
                last_page: this.state.entities.last_page,
                last_page_url: this.state.entities.last_page_url,
                next_page_url: this.state.entities.next_page_url,
                path: this.state.entities.path,
                per_page: this.state.entities.per_page,
                prev_page_url: this.state.entities.prev_page_url,
                to: this.state.entities.to,
                total: this.state.entities.total
            }
        }, () => this.props.onInvItemSelected({ itemName: value.itemName, itemId: value.itemId, itemCode: value.itemCode, specificationName: value.specificationName, sizeValue: value.sizeValue, unitName: value.unitName, unitId: value.unitId, label: value.itemName + ' ( ' + value.itemCode + ' )', value: value.itemId }))
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-2">
                        <select className="form-control form-control-sm" value={this.state.entities.per_page} onChange={this.changePerPage} style={{ "borderColor": "#e4e6ef" }}>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                    <div className="pull-right col-md-6">
                        <input type="text" placeholder="Global Search" value={this.state.globalSearch} onChange={this.changeGlobalSearch} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
                    </div>
                </div>
                <br />
                <div className="table-responsive" style={{ 'height': '550px', 'overflow': 'auto' }}>
                    <table className="table table-sm table-hover table-bordered table-condensed">
                        <thead>
                            <tr>{this.tableHeads()}</tr>
                            <tr>
                                {
                                    this.tableHeadFilter()
                                }
                            </tr>
                        </thead>
                        <tbody>{this.state.loading ? <tr><td colSpan={columns.length} className="text-center"> <Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /></td></tr> : this.dataList()}</tbody>
                    </table>

                </div>
                {(this.state.entities.data && this.state.entities.data.length > 0) &&
                    <>
                        <div className="mt-5"></div>
                        <nav>
                            <ul className="pagination">
                                <li className="page-item">
                                    <button className="page-link" disabled={1 === this.state.entities.current_page} onClick={() => this.changePage(this.state.entities.current_page - 1)}>
                                        Previous
                                    </button>
                                </li>
                                {this.pageList()}
                                <li className="page-item">
                                    <button className="page-link" disabled={this.state.entities.last_page === this.state.entities.current_page} onClick={() => this.changePage(this.state.entities.current_page + 1)}>
                                        Next
                                    </button>
                                </li>
                                <span style={{ marginTop: '8px' }}> &nbsp; <i>Displaying {this.state.entities.data.length} of {this.state.entities.total} entries.</i></span>
                            </ul>
                        </nav>
                    </>
                }
                {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
            </>
        );
    }

}

export default InvItemDt;