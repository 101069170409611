import React, { Component } from 'react';
import { Button, Spinner } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { AppContext } from '../context/AppContext';

const columns = [
	{ 'value': 'action', 'title': 'Action' },
	{ 'value': 'image', 'title': 'Image' },
	{ 'value': 'idEquipment', 'title': 'Id', 'htmtStyle': { minWidth: '100px' } },
	{ 'value': 'businessUnit', 'title': 'Business Unit', 'htmtStyle': { minWidth: '100px' } },
	{ 'value': 'equipmentName', 'title': 'Equipment Name', 'htmtStyle': { minWidth: '200px' } },
	{ 'value': 'equipmentCode', 'title': 'Equipment Code', 'htmtStyle': { minWidth: '120px' } },
	{ 'value': 'capCode', 'title': 'Fixed Asset Code', 'htmtStyle': { minWidth: '200px' } },
	{ 'value': 'engineModel', 'title': 'Engine Model' },
	{ 'value': 'engineSerial', 'title': 'Engine Serial No' },
	{ 'value': 'machineModel', 'title': 'Machine Model' },
	{ 'value': 'chassisNumber', 'title': 'Chassis Number' },
	{ 'value': 'machineSerialNo', 'title': 'Machine Serial No', 'htmtStyle': { minWidth: '120px' } },
	{ 'value': 'capacity', 'title': 'Capacity', 'htmtStyle': { minWidth: '120px' } },
	{ 'value': 'billBuName', 'title': 'Current BU' },
	{ 'value': 'sectionInchargeName', 'title': 'Section Incharge' },
	{ 'value': 'siteEngineerName', 'title': 'Site Engineer' },
	{ 'value': 'status', 'title': 'Status' }
];

const url = process.env.REACT_APP_API_URL + "equipment/equipment_dt";

class EquipmentDt extends Component {
	static contextType = AppContext;
	constructor(props) {
		super(props);
		this.state = {
			statusArr: [
				{ label: 'Active', value: 'active', },
				{ label: 'Scarp', value: 'scrap' },
				{ label: 'Sold Out', value: 'sold-out' }
			],
			entities: {
				data: [],
				current_page: 1,
				from: 1,
				last_page: 1,
				per_page: 10,
				to: 1,
				total: 1,
			},
			first_page: 1,
			current_page: 1,
			sorted_column: columns[2].value,
			offset: 4,
			order: 'DESC',
			limit: '10',
			orderBy: 'idEquipment',
			orderType: 'ASC',
			globalSearch: '',
			columns: {
				'idEquipment': {
					filterType: 'like',
					filterValue: ''
				},
				'businessUnit': {
					filterType: 'like',
					filterValue: ''
				},
				'equipmentName': {
					filterType: 'like',
					filterValue: ''
				},
				'equipmentCode': {
					filterType: 'like',
					filterValue: ''
				},
				'capCode': {
					filterType: 'like',
					filterValue: ''
				},
				'engineModel': {
					filterType: 'like',
					filterValue: ''
				},
				'engineSerial': {
					filterType: 'like',
					filterValue: ''
				},
				'machineModel': {
					filterType: 'like',
					filterValue: ''
				},
				'chassisNumber': {
					filterType: 'like',
					filterValue: ''
				},
				'machineSerialNo': {
					filterType: 'like',
					filterValue: ''
				},
				'capacity': {
					filterType: 'like',
					filterValue: ''
				},
				'billBuName': {
					filterType: 'like',
					filterValue: ''
				},
				'sectionInchargeName': {
					filterType: 'like',
					filterValue: ''
				},
				'siteEngineerName': {
					filterType: 'like',
					filterValue: ''
				},
				'status': {
					filterType: 'like',
					filterValue: ''
				},
			}
		};
	}

	fetchEntities() {
		this.setState({ loading: true });
		let fetchUrl = `${url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&globalSearch=${this.state.globalSearch}&per_page=${this.state.entities.per_page}`;
		const jwt = this.context.jwt.token;
		fetch(fetchUrl, {
			method: "POST",
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
			body: JSON.stringify(this.state)
		})
			.then((resp) => {
				return resp.json()
			})
			.then((response) => {
				this.setState({ entities: response.data.data, loading: false });

			})
			.catch((error) => {
				this.setState({ loading: false });
				console.log(error, "catch the hoop")
			});

	}

	changePage(pageNumber) {
		this.setState({ current_page: pageNumber }, () => { this.fetchEntities() });
	}

	changePerPage = evt => {

		this.setState({ entities: { ...this.state.entities, per_page: evt.target.value } }, () => { this.fetchEntities() });
	}

	changeGlobalSearch = evt => {
		this.setState({ globalSearch: evt.target.value }, () => { this.fetchEntities() });
	}

	columnsFilterChange = evt => {
		this.setState({
			columns: {
				...this.state.columns, [evt.target.name]: {
					filterType: 'like',
					filterValue: evt.target.value
				},
			}
		}, () => { this.fetchEntities() });
	}


	onStatusChange = (evt) => {
		this.setState(
			{
				columns: {
					...this.state.columns,
					status: {
						filterType: "like",
						filterValue: evt.target.value,
					},
				},
			},
			() => {
				this.fetchEntities();
			}
		);
	};

	columnHead(value) {
		return value.split('_').join(' ');
	}

	pagesNumbers() {
		if (!this.state.entities.to) {
			return [];
		}
		let from = this.state.entities.current_page - this.state.offset;
		if (from < 1) {
			from = 1;
		}
		let to = from + (this.state.offset * 2);
		if (to >= this.state.entities.last_page) {
			to = this.state.entities.last_page;
		}
		let pagesArray = [];

		for (let page = from; page <= to; page++) {
			pagesArray.push(page);
		}
		return pagesArray;
	}

	componentDidMount() {
		this.setState({ current_page: this.state.entities.current_page, loading: true }, () => { this.fetchEntities() });
	}

	tableHeads() {
		let icon;
		if (this.state.order === 'asc') {
			icon = <i className="fa fa-arrow-up"></i>;
		} else {
			icon = <i className="fa fa-arrow-down"></i>;
		}
		return columns.map((column, index) => {
			if (column.value === 'action') {
				return <th style={{ "textAlign": "center" }} key={index}>
					<p className="text-primary"> {column.title}</p>
				</th>
			}
			else {
				return <th style={{ "textAlign": "center" }} key={index} onClick={() => this.sortByColumn(column.value)}>
					<p className="text-primary"> {this.columnHead(column.title)}{column.value === this.state.sorted_column && icon}</p>
				</th>
			}

		});
	}

	tableHeadFilter() {
		return columns.map((column, index) => {
			if (column.value === 'action' || column.value === "image") {
				return <td key={index}></td>
			}
			else if (column.value === 'status') {
				return (
					<td key={index}>
						<select className="form-control form-control-sm" onChange={this.onStatusChange} style={{ width: "max-content" }}>
							<option value="">Select Status</option>
							{
								this.state.statusArr.map(function (item, id) {
									return <option key={id} value={item.value}>{item.label}</option>
								})
							}
						</select>
					</td>

				)

			}
			else {
				return <td key={index} >
					<input type="text" name={column.value} value={this.state.columns[column.value].filterValue} onChange={this.columnsFilterChange} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
				</td>
			}
		});

	}

	dataList() {
		if (this.state.entities.data.length) {
			const imagePath = process.env.REACT_APP_AWS_URL + 'asset';

			return this.state.entities.data.map((value, key) => {
				return <tr key={key} >
					<td className="text-center pr-0">
						<Link to={`/equipment-details/${value.idEquipment}`}>
							<Button className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
								<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}
									/>
								</span>
							</Button>
						</Link>
						<Link to={`/equipment-edit/${value.idEquipment}`}>
							<Button className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
								<span className="svg-icon svg-icon-md svg-icon-primary">
									<SVG
										src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
									/>
								</span>
							</Button>
						</Link>
					</td>
					<td>
						{value.image != null ?
							<img src={`${imagePath}/${value.capDetailsId}/thumb/${value.image}`} style={{ "height": "60px", "width": "60px" }} alt="" />
							:
							""
						}
					</td>
					<td className="text-center">{value.idEquipment}</td>
					<td className="text-center">{value.businessUnit}</td>
					<td className="text-center">{value.equipmentName}</td>
					<td className="text-center">{value.equipmentCode}</td>
					<td className="text-center">{value.capCode}</td>
					<td className="text-center">{value.engineModel}</td>
					<td className="text-center">{value.engineSerial}</td>
					<td className="text-center">{value.machineModel}</td>
					<td className="text-center">{value.chassisNumber}</td>
					<td className="text-center">{value.machineSerialNo}</td>
					<td className="text-center">{value.capacity}</td>
					<td className="text-center">{value.billBuName}</td>
					<td className="text-center">{value.sectionInchargeName}</td>
					<td className="text-center">{value.siteEngineerName}</td>
					<td className="text-center">
						{value.status === 'active' && <span className="label label-lg label-success label-inline mr-2">Active</span>}
						{value.status === 'scrap' && <span className="label label-lg label-info label-inline mr-2">Scrap</span>}
						{value.status === 'sold-out' && <span className="label label-lg label-danger label-inline mr-2">Sold Out</span>}
					</td>
				</tr>
			})
		} else {
			return <tr>
				<td colSpan={columns.length} className="text-center">No Records Found.</td>
			</tr>
		}
	}


	sortByColumn(column) {
		if (column === this.state.sorted_column) {
			this.state.order === 'asc' ? this.setState({ order: 'desc', current_page: this.state.first_page }, () => { this.fetchEntities() }) : this.setState({ order: 'asc' }, () => { this.fetchEntities() });
		} else {
			this.setState({ sorted_column: column, order: 'asc', current_page: this.state.first_page }, () => { this.fetchEntities() });
		}
	}

	pageList() {
		return this.pagesNumbers().map(page => {
			return <li className={page === this.state.entities.current_page ? 'page-item active' : 'page-item'} key={page}>
				<button className="page-link" onClick={() => this.changePage(page)}>{page}</button>
			</li>
		})
	}

	render() {
		const excelExportEndPoint = process.env.REACT_APP_API_URL.split("api/")[0] + 'equipment_list_excel_export?status=' + this.state.columns.status.filterValue;
		const token = this.context.jwt;
		return (
			<>
				<div className="row">
					<div className="col-md-2">
						<select className="form-control form-control-sm" value={this.state.entities.per_page} onChange={this.changePerPage} style={{ "borderColor": "#e4e6ef" }}>
							<option value="10">10</option>
							<option value="100">100</option>
							<option value="200">200</option>
							<option value="500">500</option>
							<option value="1000">1000</option>
						</select>
					</div>
					<div className="pull-right col-md-6">
						<input type="text" placeholder="Global Search" value={this.state.globalSearch} onChange={this.changeGlobalSearch} className="form-control form-control-sm" style={{ "borderColor": "#e4e6ef" }} />
					</div>
					<div className="col-md-2">
						<a href={`${excelExportEndPoint}&token=${token.token}`} className="btn btn-success btn-sm mr-3 float-center"><i className="flaticon2-download-1"></i>Excel Export</a>
					</div>
				</div>
				<br />
				<div className="table-responsive" style={{ 'height': '550px', 'overflow': 'auto' }}>
					<table className="table table-sm table-hover table-bordered table-condensed" style={{ minWidth: "max-content" }}>
						<thead>
							<tr>{this.tableHeads()}</tr>
							<tr>
								{
									this.tableHeadFilter()
								}
							</tr>
						</thead>
						<tbody>{this.state.loading ? <tr><td colSpan={columns.length} className="text-center"> <Spinner animation="grow" /><Spinner animation="grow" /><Spinner animation="grow" /></td></tr> : this.dataList()}</tbody>
					</table>

				</div>
				{(this.state.entities.data && this.state.entities.data.length > 0) &&
					<>
						<div className="mt-5"></div>
						<nav>
							<ul className="pagination">
								<li className="page-item">
									<button className="page-link" disabled={1 === this.state.entities.current_page} onClick={() => this.changePage(this.state.entities.current_page - 1)}>
										Previous
									</button>
								</li>
								{this.pageList()}
								<li className="page-item">
									<button className="page-link" disabled={this.state.entities.last_page === this.state.entities.current_page} onClick={() => this.changePage(this.state.entities.current_page + 1)}>
										Next
									</button>
								</li>
								<span style={{ marginTop: '8px' }}> &nbsp; <i>Displaying {this.state.entities.data.length} of {this.state.entities.total} entries.</i></span>
							</ul>
						</nav>
					</>
				}
			</>
		);
	}

}

export default EquipmentDt;