import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";
import { getGetRequestOptions } from "../../components/GetToken";
import { AppContext } from '../../context/AppContext';

const columns = [
    { value: "serviceOrderReceiveId", title: "Id" },
    { value: "warehouse", title: "Warehouse" },
    { value: "vendorName", title: "Vendor" },
    { value: "equipmentName", title: "Equipment Name" },
    { value: "serviceOrderSn", title: "SO SN" },
    { value: "requisitionId", title: "Tagged Requisition" },
    { value: "srrNo", title: "SRC Id" },
    { value: "serviceOrderId", title: "Service Order Id" },
    { value: "orderNo", title: "MO No" },
    { value: "categoryName", title: "Item Name" },
    { value: "quantity", title: "Quantity" },
    { value: "rate", title: "Rate" },
    { value: "totalAmount", title: "Amount" },
    { value: "srrStatus", title: "SRR Status" },
    { value: "srrDate", title: "SRC Date" },
    { value: "isSrrConfirm", title: "SRC Confirm" },
    { value: "srcConfirmBy", title: "SRC Confirm By" }
];

const url = process.env.REACT_APP_API_URL + "reports/sr_certification_report";

class SRCertificationReport extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);

        this.state = {
            warehouseOptions: [],
            fromDate: "",
            toDate: "",
            entities: {
                data: [],
                current_page: 1,
                from: 1,
                last_page: 1,
                per_page: 100,
                to: 1,
                total: 1,
            },
            first_page: 1,
            current_page: 1,
            sorted_column: columns[0].value,
            offset: 4,
            order: "DESC",
            limit: "100",
            orderBy: "Id",
            orderType: "ASC",
            globalSearch: "",
            columns: {
                serviceOrderReceiveId: {
                    filterType: "like",
                    filterValue: "",
                },
                warehouse: {
                    filterType: "like",
                    filterValue: "",
                },
                vendorName: {
                    filterType: "like",
                    filterValue: "",
                },
                equipmentName: {
                    filterType: "like",
                    filterValue: "",
                },
                serviceOrderSn: {
                    filterType: "like",
                    filterValue: "",
                },
                requisitionId: {
                    filterType: "like",
                    filterValue: "",
                },
                srrNo: {
                    filterType: "like",
                    filterValue: "",
                },

                serviceOrderId: {
                    filterType: "like",
                    filterValue: "",
                },
                orderNo: {
                    filterType: "like",
                    filterValue: "",
                },
                categoryName: {
                    filterType: "like",
                    filterValue: "",
                },
                quantity: {
                    filterType: "like",
                    filterValue: "",
                },
                rate: {
                    filterType: "like",
                    filterValue: "",
                },
                totalAmount: {
                    filterType: "like",
                    filterValue: "",
                },
                srrStatus: {
                    filterType: "like",
                    filterValue: "",
                },
                srrDate: {
                    filterType: "like",
                    filterValue: "",
                },
                isSrrConfirm: {
                    filterType: "like",
                    filterValue: "",
                },
                srcConfirmBy: {
                    filterType: "like",
                    filterValue: "",
                }
            },
        };
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    getBuWarehouse() {
        fetch(process.env.REACT_APP_API_URL + "get_warehouse_bu_wise/" + 47,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ warehouseOptions: resp.data });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    fetchEntities() {
        this.setState({ loading: true });
        let fetchUrl = `${url}?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&globalSearch=${this.state.globalSearch}&per_page=${this.state.entities.per_page}`;

        const jwt = this.context.jwt.token;

        fetch(fetchUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
            },
            body: JSON.stringify(this.state),
        })
            .then((resp) => {
                return resp.json();
            })
            .then((response) => {
                this.setState({ entities: response.data.data, loading: false });
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error, "catch the hoop");
            });
    }

    changePage(pageNumber) {
        this.setState({ current_page: pageNumber }, () => {
            this.fetchEntities();
        });
    }

    changePerPage = (evt) => {
        this.setState(
            { entities: { ...this.state.entities, per_page: evt.target.value } },
            () => {
                this.fetchEntities();
            }
        );
    };

    changeGlobalSearch = (evt) => {
        this.setState({ globalSearch: evt.target.value }, () => {
            this.fetchEntities();
        });
    };

    columnsFilterChange = (evt) => {
        this.setState(
            {
                columns: {
                    ...this.state.columns,
                    [evt.target.name]: {
                        filterType: "like",
                        filterValue: evt.target.value,
                    },
                },
            },
            () => {
                this.fetchEntities();
            }
        );
    };

    onWarehouseChange = (evt) => {
        this.setState(
            {
                columns: {
                    ...this.state.columns,
                    warehouse: {
                        filterType: "like",
                        filterValue: evt.target.value,
                    },
                },
            },
            () => {
                this.fetchEntities();
            }
        );
    };


    columnHead(value) {
        return value.split("_").join(" ");
    }

    pagesNumbers() {
        if (!this.state.entities.to) {
            return [];
        }
        let from = this.state.entities.current_page - this.state.offset;
        if (from < 1) {
            from = 1;
        }
        let to = from + this.state.offset * 2;
        if (to >= this.state.entities.last_page) {
            to = this.state.entities.last_page;
        }
        let pagesArray = [];

        for (let page = from; page <= to; page++) {
            pagesArray.push(page);
        }
        return pagesArray;
    }

    componentDidMount() {
        this.getBuWarehouse();
        this.setState({ current_page: this.state.entities.current_page, loading: true }, () => { this.fetchEntities(); });
    }

    tableHeads() {
        let icon;
        if (this.state.order === "asc") {
            icon = <i className="fa fa-arrow-up"></i>;
        } else {
            icon = <i className="fa fa-arrow-down"></i>;
        }
        return columns.map((column, index) => {

            return (
                <th
                    style={{ textAlign: "center" }}
                    key={index}
                    onClick={() => this.sortByColumn(column.value)}
                >
                    <p className="text-primary">
                        {" "}
                        {this.columnHead(column.title)}
                        {column.value === this.state.sorted_column && icon}
                    </p>
                </th>
            );
        });
    }

    tableHeadFilter() {
        return columns.map((column, index) => {
            if (column.value === 'warehouse') {
                return (
                    <td key={index}>
                        <select className="form-control form-control-sm" onChange={this.onWarehouseChange} style={{ width: "max-content" }}>
                            <option value="">Select Warehouse</option>
                            {
                                this.state.warehouseOptions.map(function (item, id) {
                                    return <option key={id} value={item.warehouseName}>{item.warehouseName}</option>
                                })
                            }
                        </select>
                    </td>

                )

            }
            else {
                return (
                    <td key={index}>
                        <input
                            type="text"
                            name={column.value}
                            value={this.state.columns[column.value].filterValue}
                            onChange={this.columnsFilterChange}
                            className="form-control form-control-sm"
                            style={{ borderColor: "#e4e6ef" }}
                        />
                    </td>
                );

            }
        });
    }

    dataList() {
        if (this.state.entities.data.length) {
            return this.state.entities.data.map((value, key) => {
                return (
                    <tr key={key}>
                        <td className="text-center">{value.serviceOrderReceiveId}</td>
                        <td className="text-center">{value.warehouse}</td>
                        <td className="text-center">{value.vendorName}</td>
                        <td className="text-center">{value.equipmentName}</td>
                        <td className="text-center">{value.serviceOrderSn}</td>
                        <td className="text-center">{value.requisitionId}</td>
                        <td className="text-center">{value.srrNo}</td>
                        <td className="text-center">{value.serviceOrderId}</td>
                        <td className="text-center" style={{ whiteSpace: "nowrap" }}>
                            {value.orderNo}
                        </td>
                        <td className="text-center" style={{ whiteSpace: "nowrap", fontSize: "smaller" }}>
                            {parse(value.categoryName)}
                        </td>
                        <td className="text-center" style={{ whiteSpace: "nowrap", fontSize: "smaller" }}>
                            {parse(value.quantity)}
                        </td>
                        <td className="text-center" style={{ whiteSpace: "nowrap", fontSize: "smaller" }}>
                            {parse(value.rate)}
                        </td>
                        <td className="text-center" style={{ whiteSpace: "nowrap", fontSize: "smaller" }}>
                            {parse(value.totalAmount)}
                        </td>
                        <td className="text-center">
                            {value.srrStatus === "created" && <span className="label label-lg label-primary label-inline mr-2">Created</span>}
                            {value.srrStatus === "approved" && <span className="label label-lg label-success label-inline mr-2">Approved</span>}
                            {value.srrStatus === "denied" && <span className="label label-lg label-danger label-inline mr-2">Denied</span>}
                        </td>
                        <td className="text-center">{value.srrDate}</td>
                        {value.isSrrConfirm === "yes" && <td>Yes</td>}
                        {value.isSrrConfirm === "no" && <td>No</td>}
                        {value.isSrrConfirm === null && <td>&nbsp;</td>}
                        <td>{value.srcConfirmBy}</td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={columns.length} className="text-center">
                        No Records Found.
                    </td>
                </tr>
            );
        }
    }

    sortByColumn(column) {
        if (column !== 'requisitionId') {
            if (column !== 'serviceOrderSn') {
                if (column === this.state.sorted_column) {
                    this.state.order === "asc"
                        ? this.setState(
                            { order: "desc", current_page: this.state.first_page },
                            () => {
                                this.fetchEntities();
                            }
                        )
                        : this.setState({ order: "asc" }, () => {
                            this.fetchEntities();
                        });
                } else {
                    this.setState(
                        {
                            sorted_column: column,
                            order: "asc",
                            current_page: this.state.first_page,
                        },
                        () => {
                            this.fetchEntities();
                        }
                    );
                }
            }
        }
    }

    pageList() {
        return this.pagesNumbers().map((page) => {
            return (
                <li
                    className={
                        page === this.state.entities.current_page
                            ? "page-item active"
                            : "page-item"
                    }
                    key={page}
                >
                    <button className="page-link" onClick={() => this.changePage(page)}>
                        {page}
                    </button>
                </li>
            );
        });
    }

    render() {
        return (
            <div className="card card-custom">
                <div className="card-header">
                    <h3 className="card-title">
                        <p className="text-primary">SR Certification List</p>
                    </h3>
                </div>

                <div className="card-body">
                    <div className="row">
                        <label htmlFor="fromDate" className="col-lg-1 col-form-label" style={{ "textAlign": "right" }}>From Date</label>
                        <div className="col-lg-2">
                            <input type="date" name="fromDate" id="fromDate" value={this.state.fromDate} onChange={this.handleInputOnChange} className="form-control form-control-sm" />
                        </div>
                        <label htmlFor="toDate" className="col-lg-1 col-form-label" style={{ "textAlign": "right" }}>To Date</label>
                        <div className="col-lg-2">
                            <input type="date" name="toDate" id="toDate" value={this.state.toDate} onChange={this.handleInputOnChange} className="form-control form-control-sm" />
                        </div>
                        <div className="col-md-2">
                            <Button className="btn btn-outline-success btn-sm" onClick={() => this.fetchEntities()}> Show </Button>
                        </div>
                        <div className="col-md-3">
                            <input
                                type="text"
                                placeholder="Global Search"
                                value={this.state.globalSearch}
                                onChange={this.changeGlobalSearch}
                                className="form-control form-control-sm"
                                style={{ borderColor: "#e4e6ef" }}
                            />
                        </div>
                        <div className="col-md-1">
                            <select
                                className="form-control form-control-sm"
                                value={this.state.entities.per_page}
                                onChange={this.changePerPage}
                                style={{ borderColor: "#e4e6ef" }}
                            >
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                                <option value="5000">5000</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div className="tableFixHead">
                        <div className="table-responsive" style={{ maxHeight: "600px" }}>
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>{this.tableHeads()}</tr>
                                    <tr>{this.tableHeadFilter()}</tr>
                                </thead>
                                <tbody>
                                    {this.state.loading ? (
                                        <tr>
                                            <td colSpan={columns.length} className="text-center">
                                                {" "}
                                                <Spinner animation="grow" />
                                                <Spinner animation="grow" />
                                                <Spinner animation="grow" />
                                            </td>
                                        </tr>
                                    ) : (
                                        this.dataList()
                                    )}
                                </tbody>
                            </table>

                            {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                        </pre> */}
                        </div>
                    </div>
                    {this.state.entities.data && this.state.entities.data.length > 0 && (
                        <>
                            <div className="pt-2"></div>
                            <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            disabled={1 === this.state.entities.current_page}
                                            onClick={() =>
                                                this.changePage(this.state.entities.current_page - 1)
                                            }
                                        >
                                            Previous
                                        </button>
                                    </li>
                                    {this.pageList()}
                                    <li className="page-item">
                                        <button
                                            className="page-link"
                                            disabled={
                                                this.state.entities.last_page ===
                                                this.state.entities.current_page
                                            }
                                            onClick={() =>
                                                this.changePage(this.state.entities.current_page + 1)
                                            }
                                        >
                                            Next
                                        </button>
                                    </li>
                                    <span style={{ marginTop: "8px" }}>
                                        {" "}
                                        &nbsp;{" "}
                                        <i>
                                            Displaying {this.state.entities.data.length} of{" "}
                                            {this.state.entities.total} entries.
                                        </i>
                                    </span>
                                </ul>
                            </nav>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default SRCertificationReport;
