import React, { Component } from 'react';
import DataTable from '../components/DataTable'
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import ToastMsg from '../components/ToastMsg';
import { AppContext } from '../context/AppContext';
const columns = [
    { 'value': 'id', 'title': 'Id' },
    { 'value': 'equipment', 'title': 'Equipment' },
    { 'value': 'emo', 'title': 'EMO' },
    { 'value': 'bu', 'title': 'Business Unit' },
    { 'value': 'status', 'title': 'Status' }
];

const filters = {
    'id': {
        filterType: 'like',
        filterValue: ''
    },
    'equipment': {
        filterType: 'like',
        filterValue: ''
    },
    'emo': {
        filterType: 'like',
        filterValue: ''
    },
    'bu': {
        filterType: 'like',
        filterValue: ''
    },
    'status': {
        filterType: 'like',
        filterValue: ''
    }

};

const url = process.env.REACT_APP_API_URL + "equipment_buffer_dt";


class BufferList extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = { newData: "", name: "" }
    }
    onCreate = ({ id, name }) => {
        this.setState({ newMake: { id: id, name: name } });
    }
    
    render() {
        return (
            <div className="card card-custom">


                <div className="card-header">
                    <h3 className="card-title"><p className="text-primary">Buffer List</p></h3>
                </div>
                <div className="card-body">
                    <DataTable url={url} columns={columns} filters={filters} newData={this.state.newData} >
                        
                    </DataTable>

                </div>

            </div >

        );
    }
}

export default BufferList;