/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {

	const uiService = useHtmlClassService();
	const layoutProps = useMemo(() => {
		return {
			light: objectPath.get(uiService.config, "extras.user.dropdown.style") === "light",
		};
	}, [uiService]);

	const [fullname, setFullname] = useState(null);
	const [pic, setPic] = useState(null);

	const getRootDomain = () => {
		var root_domain = "";
		if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(location.host.split(':')[0])) {
			return location.host.split(':')[0];
		} else if (location.host.indexOf('localhost') !== -1) {
			return 'localhost';
		} else {
			var temp = location.host.split('.').reverse();
			root_domain = '.' + temp[1] + '.' + temp[0];
			return root_domain;
		}
	}

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	React.useEffect(() => {
		var jwt = { token: getCookie('MyToken') };
		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "users/get_user_info", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				//this.setState({ fullname: resp.data.fullName,pic:resp.data.fileName });
				setFullname(resp.data.fullName);
				setPic(resp.data.fileName);
			})
			.catch((error) => {
				document.cookie = 'MyToken=;path=/;domain=' + getRootDomain() + ';expires=' + new Date(0).toUTCString();
				window.location.reload();
				console.log(error, "catch the hoop")
			});


	}, []); // <-- Have to pass in [] here!


	return (
		<Dropdown drop="down" alignRight>
			<Dropdown.Toggle
				as={DropdownTopbarItemToggler}
				id="dropdown-toggle-user-profile"
			>
				<div className={"btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"}>
					{fullname !== null &&
						<img src={process.env.REACT_APP_USER_ASSET + pic} alt="" style={{ width: "40px", height: "40px" }} />
					}

					<span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
						{fullname}
					</span>
					<span className="symbol symbol-35 symbol-light-success">
						{/* <span className="symbol-label font-size-h5 font-weight-bold">{user.fullname[0]}</span> */}
					</span>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu
				className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
				<>
					{/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
					{layoutProps.light && (<>
						<div className="d-flex align-items-center p-8 rounded-top">

							<div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
								{fullname !== null &&
									<img src={process.env.REACT_APP_USER_ASSET + pic} alt="" />
								}
							</div>
							<div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">{fullname} </div>
							<span className="label label-light-success label-lg font-weight-bold label-inline">3 messages</span>
						</div>
						<div className="separator separator-solid"></div>
					</>)}

					{!layoutProps.light && (
						<div
							className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
							style={{
								backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
							}}
						>
							<div className="symbol bg-white-o-15 mr-3">
								{fullname !== null &&
									<img src={process.env.REACT_APP_USER_ASSET + pic} alt="" />
								}
							</div>
							<div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
								{fullname}
							</div>
							{/* <span className="label label-success label-lg font-weight-bold label-inline">
                    3 messages
                  </span> */}
						</div>
					)}
				</>

				<div className="navi navi-spacer-x-0 pt-5">
					<div className="navi-item px-8">
						<div className="navi-link">
							<div className="navi-icon mr-2">
								<i className="flaticon2-calendar-3 text-success" />
							</div>
							<div className="navi-text">
								<div className="font-weight-bold">
									<a target='_blank' href={process.env.REACT_APP_LOGIN + '#/profile/basic'} className='menu-link' rel="noopener noreferrer">
										My Profile
									</a>
								</div>
								<div className="text-muted">
									Account settings and more
									<span className="label label-light-danger label-inline font-weight-bold">update</span>
								</div>
							</div>
						</div>
					</div>
					<div className="navi-separator mt-3"></div>

					<div className="navi-footer  px-8 py-5">
						<Link to="/logout" className="btn btn-light-primary font-weight-bold">
							Sign Out
						</Link>
					</div>
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
}
