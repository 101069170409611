import React, { Component } from 'react';
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getGetRequestOptions } from "../components/GetToken";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { AppContext } from '../context/AppContext';
import DenyMo from '../components/DenyMo';

class MaintenanceOrderDetails extends Component {
    static contextType = AppContext;
    state = {
        maintOrderId: "",
        invItemTagPermission: "",
        moApprovalPermission: "",
        details: {
            equipment: { equipmentName: '' },
            team: { teamName: '' },
            maintenancetype: "",
            priority: "",
            equipmentCondition: "",
            responsibleperson: [],
            businessunit: { businessunitName: '' },
            maint_businessunit: { maintBusinessunitName: '' },
            parts: [],
            operations: [],
            maintenance_status: [],
        },
        totalPartsCost: 0,
        totalOperationsCost: 0,
        totalCost: 0
    }

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;

        this.setState({ maintOrderId: this.props.match.params.id });
        this.getMaintenanceOrderDetails(id);
        this.getInvItemTagPermission();
        this.getMoApprovalPermission();
    }

    getMaintenanceOrderDetails(maintOrderId) {
        fetch(process.env.REACT_APP_API_URL + "maintenance_order/order_details/" + maintOrderId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ details: resp }, () => this.calcTotal());
                ;
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getInvItemTagPermission() {
        fetch(process.env.REACT_APP_API_URL + "inv_item_tag_permission", getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ invItemTagPermission: resp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    getMoApprovalPermission() {
        fetch(process.env.REACT_APP_API_URL + "mo_approval_permission", getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ moApprovalPermission: resp });
                //console.log(this.state.moApprovalPermission);
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);

    }
    calcTotal = () => {
        var totalPartsCost = 0;
        this.state.details.parts.forEach(function (item) {
            totalPartsCost += item.invStatus === 'Issued' ? Number(item.price) * Number(item.issuedQty) : Number(item.price) * Number(item.quantity);

        });
        this.setState({ totalPartsCost: (totalPartsCost).toFixed(2) });
        const totalOperationsCost = this.objPsum(this.state.details.operations, 'amount');
        this.setState({ totalOperationsCost: (totalOperationsCost).toFixed(2) });
        const totalCost = Number(totalPartsCost) + Number(totalOperationsCost);
        this.setState({ totalCost: (totalCost).toFixed(2) });
    }

    denyMo = (moNo, maintOrderId) => {
        this.setState({ denyMoModalShow: true, maintOrderId: maintOrderId, moNo: moNo });
    }

    onDenyMo = () => {
        this.setState({ denyMoModalShow: false });
        this.getMaintenanceOrderDetails(this.state.maintOrderId);
        this.invItemTagPermission();
        this.getMoApprovalPermission();
    }

    approveMo = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to approve this Maintenance Order!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = {
                    maintOrderId: this.state.maintOrderId,
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                var apiEnd = "maintenance_order/approve/" + this.state.maintOrderId;

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'Maintenance Order has been approved successfully!',
                            })
                            this.getMaintenanceOrderDetails(this.state.maintOrderId);
                            this.invItemTagPermission();
                            this.getMoApprovalPermission();
                        }
                        else {
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                errorsMessage.push("Something went wrong");
                            }
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                text: errorsMessage,
                            })
                        }


                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })
    }

    render() {
        const resListItem = this.state.details.responsibleperson.map((person, key) =>
            <li key={key} >{`${person.employeeName} ${person.employeeCode}`}</li>
        );

        let denyMoModalClose = () => {
            this.setState({ denyMoModalShow: false });
        };

        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label">
                                Maintenance Order  #{this.state.details.orderNo}
                            </h3>
                        </div>

                        <div className="card-toolbar mt-5" >
                            {(this.state.details.status === "created" && this.state.moApprovalPermission !== ''  && this.state.moApprovalPermission?.userId === this.state.details.approver_id)&&
                                <>
                                    <button className="btn btn-success btn-sm" onClick={this.approveMo}>Approve</button>
                                    {this.state.details.hasReservation === 0 &&
                                        <button className="btn btn-danger btn-sm" onClick={() => this.denyMo(this.state.details.moNo, this.state.maintOrderId)}>Deny</button>

                                    }
                                </>
                            }
                            {/* (this.state.details.status !== "completed" && this.state.details.status !== "denied" && this.state.details.hasAccessMoDetailsConfirm === 1*/}
                            {(this.state.details.status !== "completed" ) &&
                                <Link to={`/maintenance-order-edit/${this.state.maintOrderId}`}>
                                    <button className="btn btn-outline-primary btn-sm"><i className="flaticon2-plus"></i>Edit Maintenace Order</button>
                                </Link>
                            }
                            {(this.state.details.status === "approved" && this.state.invItemTagPermission !== "") &&
                                <Link to={`/maintenance-order-tag-inv-item/${this.state.maintOrderId}`}>
                                    <button className="btn btn-outline-success btn-sm ml-2"><i className="flaticon2-clip-symbol"></i>Tag Inv Item</button>
                                </Link>
                            }
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-6">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Equipment</th>
                                            <td>{this.state.details.equipment.equipmentName}</td>
                                        </tr>
                                        <tr>
                                            <th>Equipment Code</th>
                                            <td>{this.state.details.equipment.equipmentCode}</td>
                                        </tr>
                                        <tr>
                                            <th>Maintenance Order No</th>
                                            <td>{this.state.details.orderNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Order Date</th>
                                            <td>{this.state.details.orderDate}</td>
                                        </tr>
                                        <tr>
                                            <th>Title</th>
                                            <td>{this.state.details.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Maintenance Type</th>
                                            <td>{this.state.details.maintenancetype != null ? this.state.details.maintenancetype.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Approver</th>
                                            <td>{this.state.details.approver != null ? this.state.details.approver.fullName : ''}</td>
                                        </tr>
                                        {this.state.details.completeDate != null &&
                                            <tr>
                                                <th>Complete Date</th>
                                                <td>{this.state.details.completeDate}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="col-6">
                                <table className="table table-bordered table-hover">
                                    <tbody>
                                        <tr>
                                            <th>Equipment Condition</th>
                                            <td>{this.state.details.equipmentCondition}</td>
                                        </tr>
                                        <tr>
                                            <th>Priority</th>
                                            <td>{this.state.details.priority === "high" && "High"}{this.state.details.priority === "medium" && "Medium"}{this.state.details.priority === "low" && "Low"}</td>
                                        </tr>
                                        <tr>
                                            <th>Responsible Person</th>
                                            <td><ul>{resListItem}</ul></td>
                                        </tr>
                                        <tr>
                                            <th> Equipment Location BU</th>
                                            <td>{this.state.details.maint_businessunit != null ? this.state.details.maint_businessunit.maintBusinessunitName : ''}</td>
                                        </tr>
                                        <tr>
                                            <th>Closing Hrs Meter</th>
                                            <td>{this.state.details.closingHrsMeter}</td>
                                        </tr>
                                        <tr>
                                            <th>Category</th>
                                            <td>{this.state.details.category === "breakdown_maintenance" ? "Breakdown Maintenance" : "Schedule Maintenance"}</td>
                                        </tr>
                                        {this.state.details.status === "denied" &&
                                            <tr>
                                                <th>Reason</th>
                                                <td>{this.state.details.denyReason}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                                {this.state.details.status === 'created' && <span className='label label-lg label-primary label-inline mr-2'>Created</span>}
                                                {this.state.details.status === 'approved' && <span className='label label-lg label-info label-inline mr-2'>Approved</span>}
                                                {this.state.details.status === 'denied' && <span className='label label-lg label-danger label-inline mr-2'>Denied</span>}
                                                {this.state.details.status === 'completed' && <span className='label label-lg label-success label-inline mr-2'>Completed</span>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-12 mt-10">
                            <Tabs defaultActiveKey="parts" id="uncontrolled-tab-example">
                                <Tab eventKey="parts" title="Parts">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Warehouse</th>
                                                    <th>Material Name</th>
                                                    <th>Material Desc</th>
                                                    <th>Inv Item</th>
                                                    <th>Item Code</th>
                                                    <th>Unit</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Issue Quantity</th>
                                                    <th>Cost</th>
                                                    <th>Inventory Status</th>
                                                    <td>Completed</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.details.parts.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.warehouseName}</td>
                                                            <td>{item.materialName}</td>
                                                            <td>{item.materialDesc}</td>
                                                            <td>{item.item !== null ? item.item.itemName : ""}</td>
                                                            <td>{item.item !== null ? item.item.itemCode : ""}</td>
                                                            <td>{item.item !== null ? item.item.unitName : ""}</td>
                                                            <td>{item.price} </td>
                                                            <td>{item.quantity}</td>
                                                            <td>{Number(item.issuedQty).toFixed(2)}</td>
                                                            <td>{item.invStatus === 'Issued' ? (Number(item.price) * Number(item.issuedQty)).toFixed(2) : (Number(item.price) * Number(item.quantity)).toFixed(2)}</td>
                                                            <td>{item.invStatus === "Booked" && <span className={'label label-lg label-primary label-inline mr-2'}>Booked</span>}
                                                                {item.invStatus === "Issued" && <span className={'label label-lg label-success label-inline mr-2'}>Issued</span>}
                                                            </td>
                                                            <td><span className={item.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{item.isComplete === 'no' ? 'No' : 'Yes'}</span></td>
                                                        </tr>

                                                    )
                                                }

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="10" style={{ "textAlign": "right" }}>
                                                        Total
                                                    </td>
                                                    <td colSpan="3">{this.state.totalPartsCost}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </Tab>

                                <Tab eventKey="operations" title="Operations" >
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                            <thead>
                                                <tr>
                                                    <th>SL</th>
                                                    <th>Operation</th>
                                                    <th>Operation Short Desc</th>
                                                    <th>Work Start Date</th>
                                                    <th>Work Finish Date</th>
                                                    <th>Duration(h:m)</th>
                                                    <th>Has SRC</th>
                                                    <th>SRC ID</th>
                                                    <th>Amount</th>
                                                    <th>SRC Confirm </th>
                                                    <td>Completed</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.details.operations.map((operation, index) =>

                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{operation.operationTitle}</td>
                                                            <td>{operation.operationDetails}</td>
                                                            <td>{operation.startDate}</td>
                                                            <td>{operation.endDate}</td>
                                                            <td>
                                                                {operation.duration.split(":")[0] + ':' + operation.duration.split(":")[1]}
                                                            </td>
                                                            <td>{operation.hasSrr === "yes" ? "Yes" : "No"}</td>
                                                            <td>{operation.srrNo}</td>
                                                            <td>{operation.amount}</td>
                                                            {operation.isSrrConfirm === "yes" && <td>Yes</td>}
                                                            {operation.isSrrConfirm === "no" && <td>No</td>}
                                                            {operation.isSrrConfirm === null && <td></td>}
                                                            <td><span className={operation.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{operation.isComplete === 'no' ? 'No' : 'Yes'}</span></td>

                                                        </tr>

                                                    )
                                                }

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="8" style={{ "textAlign": "right" }}>
                                                        Total
                                                    </td>
                                                    <td colSpan="3">{this.state.totalOperationsCost}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </Tab>
                            </Tabs>
                            <div className="col-md-4 offset-md-8 mt-10">
                                <div className="row">
                                    <div className="col-md-6"><b>Total Parts Cost</b></div>
                                    <div className="col-md-3">{this.state.totalPartsCost}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6"><b>Total Operations Cost</b></div>
                                    <div className="col-md-3">{this.state.totalOperationsCost}</div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6"><b>Total Cost</b></div>
                                    <div className="col-md-3">{this.state.totalCost}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="card card-custom">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">
                                            Maintenace Order Timeline
                                        </h3>
                                    </div>
                                </div>

                                <div className="card-body">
                                    <div className="timeline timeline-3">
                                        <div className="timeline-items">
                                            {
                                                this.state.details.maintenance_status.map((item, index) =>
                                                    <div className="timeline-item" key={index}>
                                                        <div className="timeline-media">
                                                            {item.status === "created" && <i className="flaticon2-plus text-primary "></i>}
                                                            {item.status === "approved" && <i className="flaticon2-user text-info"></i>}
                                                            {item.status === "denied" && <i className="flaticon2-cross text-danger"></i>}
                                                            {item.status === "completed" && <i className="flaticon2-check-mark text-success"></i>}

                                                        </div>
                                                        <div className="timeline-content">
                                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                                <div className="mr-4">
                                                                    <span className="mr-3 font-weight-bolder" style={{ "fontSize": "15px", "color": "#e35b5a" }}>
                                                                        Maintenance Order No {this.state.details.orderNo} has been
                                                                        {item.status === "created" && " Created"}
                                                                        {item.status === "approved" && " Approved"}
                                                                        {item.status === "denied" && " Denied"}
                                                                        {item.status === "completed" && " Completed"}

                                                                        <span className="text-muted ml-2"> at {item.create_date}</span>
                                                                    </span>
                                                                    {item.status === "created" &&
                                                                        <>
                                                                            <p className="mr-3">
                                                                                Order Date : {this.state.details.orderDate} <br></br>
                                                                                Created By : <a href="/#"><b>{item.fullName}</b></a>
                                                                            </p>
                                                                        </>
                                                                    }
                                                                    {item.status === "approved" &&
                                                                        <>
                                                                            <p className="mr-3">
                                                                                Approved By : <a href="/#"><b>{item.fullName}</b></a>

                                                                            </p>
                                                                        </>
                                                                    }
                                                                    {item.status === "denied" &&
                                                                        <>
                                                                            <p className="mr-3">
                                                                                Denied By : <a href="/#"><b>{item.fullName}</b></a>

                                                                            </p>
                                                                        </>
                                                                    }

                                                                    {item.status === "completed" &&
                                                                        <>
                                                                            <p className="mr-3">
                                                                                Complete Date : {this.state.details.completeDate} <br></br>
                                                                                Completed By : <a href="/#"><b style={{ "color": "rgb(45, 197, 27)" }}>{item.fullName}</b></a>
                                                                            </p>
                                                                        </>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={this.state.denyMoModalShow} onHide={denyMoModalClose} aria-labelledby="example-modal-sizes-title-lg" size='lg'>
                        <Modal.Body>
                            <DenyMo onDenyMo={this.onDenyMo} maintOrderId={this.state.maintOrderId} moNo={this.state.details.orderNo} />
                        </Modal.Body>
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={denyMoModalClose}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                    {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                    </pre> */}
                </div>
                {/* <ToastContainer /> */}
            </>

        );
    }
}

export default MaintenanceOrderDetails;