/* eslint-disable no-restricted-globals */
/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Logout from "./pages/Logout";
import PaymentWarning from './components/PaymentWarning';

export function Routes() {

    var redirect_url = process.env.REACT_APP_URL;
    redirect_url = btoa(redirect_url);
    redirect_url = encodeURIComponent(redirect_url);

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    var jwt = { token: getCookie('MyToken') };

    return (
        <>
		<PaymentWarning/>
        <Switch>

            <Route path="/error" component={ErrorsPage} />
            <Route path="/logout" component={Logout} />

            {jwt.token === undefined ? (
                /* Redirect to `/auth` when user is not authorized */
                <Route path='/' component={() => {
                    window.location.href = process.env.REACT_APP_LOGIN + 'login/index/' + redirect_url;
                    return null;
                }} />
            )
                : (
                    <Layout>
                        <BasePage />
                    </Layout>
                )}
        </Switch>
        </>
    );
}
