import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { getGetRequestOptions } from "../components/GetToken";
import { AppContext } from '../context/AppContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};


class FuelLogEntry extends Component {
    static contextType = AppContext;
    state = {
        equipment: "",
        logDate: "",
        fuelQty: "",
        hrsMeter: "",
    }

    componentDidMount() {
        const { ...baseState } = this.state;
        this.baseState = baseState;
    }

    getEquipment = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        } else {
            var url = process.env.REACT_APP_API_URL + "equipment/fuel_officer_equipment_search?q=" + inputValue;

            setTimeout(() => {
                fetch(url, getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.equipmentName} (${element.equipmentCode})`;
                            element.value = element.equipmentId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    handleEquipmentChange = equipment => {

        this.setState({ equipment: equipment });
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleHourChange = evt => {
        const value = evt.target.value;
        if (value > 24) {
            evt.target.value = 24;
        }
        this.setState({ hrs: evt.target.value, breakdownHour: `${value}:${this.state.mins}` });
    }

    handleMinuteChange = evt => {
        const value = evt.target.value;
        if (value > 59) {
            evt.target.value = 59;
        }
        this.setState({ mins: evt.target.value, breakdownHour: `${this.state.hrs}:${value}` });
    }

    handleSubmit = evt => {
        evt.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to entry this fuel log!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = {
                    ...this.state, project: {
                        projectId: this.state.equipment.projectId
                    },
                    tankCapacity: this.state.equipment.tankCapacity
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                var apiEnd = "log_book/fuel_entry";

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            let successMsg = resp.successMessage;
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })

                            this.setState(this.baseState);
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                text: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }

    render() {
        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            <p className="text-primary">
                                Fuel Log Entry
                            </p>
                        </h3>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className='row'>
                                    <div className='col-2'></div>
                                    <div className='col-8'>
                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Equipment<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <AsyncSelect
                                                    value={this.state.equipment}
                                                    defaultOptions
                                                    loadOptions={this.getEquipment}
                                                    placeholder="Select Equipment"
                                                    onChange={this.handleEquipmentChange}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="currentProject" className="col-lg-2 col-form-label">Current Project<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="text" name="currentProject" id="currentProject" value={this.state.equipment.currentProject || ""} readOnly className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="fuelType" className="col-lg-2 col-form-label">Fuel Type</label>
                                            <div className="col-lg-6">
                                                <input type="text" name="fuelType" id="fuelType" value={this.state.equipment.fuelType} readOnly={this.state.equipment.fuelType || ""} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="tankCapacity" className="col-lg-2 col-form-label">Tank Capacity<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <div className="input-group input-group-md">
                                                    <input type="number" name="tankCapacity" id="tankCapacity" value={this.state.equipment.tankCapacity || ""} readOnly className={`form-control form-control-md`} />
                                                    <div className="input-group-append"><span className="input-group-text">Ltr</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="fuelQty" className="col-lg-2 col-form-label">Fuel Qty<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <div className="input-group input-group-md">
                                                    <input type="number" name="fuelQty" id="fuelQty" value={this.state.fuelQty} onChange={this.handleInputOnChange} max={this.state.equipment.tankCapacity} className={`form-control form-control-md`} />
                                                    <div className="input-group-append"><span className="input-group-text">Ltr</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="logDate" className="col-lg-2 col-form-label">Refueling Date<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="date" name="logDate" id="logDate" value={this.state.logDate} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="previousHrsMeter" className="col-lg-2 col-form-label">Previous Hrs Meter</label>
                                            <div className="col-lg-6">
                                                <input type="number" name="previousHrsMeter" id="previousHrsMeter" value={this.state.equipment.previousHrsMeter} readOnly={this.state.equipment.previousHrsMeter || ""} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="hrsMeter" className="col-lg-2 col-form-label">Current Hrs Meter<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="number" name="hrsMeter" id="hrsMeter" value={this.state.hrsMeter} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="row">
                                <div className="col-4">
                                </div>
                                <div className="col-6">
                                    <button type="submit" className="btn btn-success mr-2">Submit</button>
                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                        {/* <pre>
                            {JSON.stringify(this.state, null, 2)}
                        </pre> */}
                    </form>

                </div>
            </>
        );
    }
}

export default FuelLogEntry;