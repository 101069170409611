import React, { useState, useEffect, useContext } from 'react';
import SVG from "react-inlinesvg";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getGetRequestOptions } from "../components/GetToken";
import { AppContext } from '../context/AppContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

const EquipmentBuffer = (props) => {
    const { jwt } = useContext(AppContext);

    const [businessUnit] = useState(process.env.REACT_APP_EMD_BU_ID);
    const [fromBu, setFromBu] = useState("");
    const [items, setItems] = useState([{ equipment: "", equipmentId: "", emoId: "", emoDetailsId: "", itemName: "", model: "", capCode: "", remark: "", idReleasingDepartment: "", location: "", emoDate: "", receiveDate: "" }]);
    const [allBusinessUnits, setAllBusinessUnits] = useState([]);


    useEffect(() => {
       
        getAllBu();
        
    }, []);


    const handleFromBuChange = businessUnit => {
        setFromBu(businessUnit);
        setItems([{ equipmentId: "", emoId: "", emoDetailsId: "", itemName: "", model: "", capCode: "", remark: "", idReleasingDepartment: "", location: "", emoDate: "", receiveDate: ""}]);
    };

    const getAllBu = () => {
        fetch(process.env.REACT_APP_API_URL + "get_business_units", getGetRequestOptions())
            .then((resp) => resp.json())
            .then((resp) => {
                let tempResp = resp.data.map(element => ({
                    ...element,
                    label: element.projectName,
                    value: element.projectId,
                    isdisabled: element.projectName === 'EMD' ? false : true
                })).filter(e => e.projectId !== process.env.REACT_APP_EMD_BU_ID);
                setAllBusinessUnits(tempResp);
            })
            .catch((error) => console.log(error));
    };


    const getEquipment = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            const projectId = fromBu.projectId;
            const url = `${process.env.REACT_APP_API_URL}equipment/received_equipment_search/${projectId}?q=${inputValue}`;
            setTimeout(() => {
                fetch(url, getGetRequestOptions())
                    .then((resp) => resp.json())
                    .then((resp) => {
                        const tempArray = resp.data.map(element => ({
                            ...element,
                            label: `${element.equipmentName} (${element.equipmentCode})`,
                            value: element.equipmentId
                        }));
                        callback(tempArray);
                    })
                    .catch((error) => console.log(error));
            });
        }
    };

    const getProjectLocation = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);
        } else {
            const projectId = fromBu.projectId;
            const api = `${process.env.REACT_APP_API_URL}project_location/${projectId}?q=${inputValue}`;
            setTimeout(() => {
                fetch(api, getGetRequestOptions())
                    .then((resp) => resp.json())
                    .then((resp) => {
                        const tempArray = resp.data.map(element => ({
                            ...element,
                            id: element.locationId,
                            label: `${element.locationName} ( ${element.projectName} )`,
                            value: element.locationId
                        }));
                        callback(tempArray);
                    })
                    .catch((error) => console.log(error));
            });
        }
    };

    const onEquipmentChange = index => item => {
        const newItems = items.map((row, rindex) => (index === rindex ? {
            ...row,
            equipment: item,
            equipmentId: item.equipmentId,
            emoId: item.emoId,
            emoDetailsId: item.emoDetailsId,
            itemName: `${item.equipmentName}  (${item.equipmentCode})`,
            model: item.model,
            capCode: item.capCode,
            emoDate: item.emoDate,
            receiveDate: item.receiveDate
        } : row));
        setItems(newItems);
    };


    const onremarkChange = index => evt => {
        const newItems = items.map((row, rindex) => (index === rindex ? {
            ...row,
            remark: evt.target.value
        } : row));
        setItems(newItems);
    };

    const onProjectLocationChange = index => item => {
        const newItems = items.map((row, rindex) => (index === rindex ? {
            ...row,
            location: item
        } : row));
        setItems(newItems);
    };



    const addItem = () => {
        const  newItem = { equipment: "", equipmentId: "", emoId: "", emoDetailsId: "", itemName: "", model: "", capCode: "", remark: "", location: "", emoDate: "", receiveDate: "",  };
        setItems([...items, newItem]);       

    };
    

    const removeItem = (index) => {
        setItems(items.filter((row, rindex) => index !== rindex));
    };

    const handleSubmit = evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to create Equipment  Buffer!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const formData = {
                    businessUnit,
                    fromBu,
                    items
                };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },
                    body: JSON.stringify(formData)
                };

                fetch(process.env.REACT_APP_API_URL + "equipment_buffer/save", requestOptions)
                    .then((resp) => resp.json())
                    .then((resp) => {
                        if (resp.success === true) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'Equipment Buffer created successfully.',
                            });
                            
                            props.history.push(`/buffer-list`);
                        } else {
                            const errorsMessage = [];
                            if (typeof resp.errorMessage === 'object') {
                                Object.keys(resp.errorMessage).forEach(key => {
                                    resp.errorMessage[key].forEach(err => errorsMessage.push(err));
                                });
                            } else if (typeof resp.errorMessage === 'string') {
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                errorsMessage.push("Something went wrong");
                            }
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                html: errorsMessage.join('<br>'),
                            });
                        }
                    })
                    .catch((error) => console.log(error));
            }
        });
    };

		
		return (
			<>
				<div className="card card-custom">
					<div className="card-header">
						<h3 className="card-title"><p className="text-primary">Equipment Buffer</p></h3>
					</div>

					<form onSubmit={handleSubmit}>
						<div className="card-body">
							<div className="col ml-12">

								
									<div className="form-group row">
										<label htmlFor="example-text-input" className="col-lg-2 col-form-label">Business Unit <span className="required text-danger"> *</span></label>
										<div className="col-lg-6">
											<Select
												value={fromBu}
												onChange={handleFromBuChange}
												options={allBusinessUnits}
											/>
										</div>
									</div>
								
								
							</div>
							<div className="mt-5">
								<div className="table-responsive">
									<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
										<thead>
											<tr>
												<th>Sl. No</th>
												<th>Equipment</th>
												<th>Equipment Name</th>
												<th>Model</th>
												<th>Capitalization Code</th>
												<th>Emo Date</th>
												<th>Receive Date</th>
												<th>Location</th>
												<th>Remark</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>
											{
												items.map((item, index) =>
													<tr key={index}>
														<td>{index + 1}</td>
														<td style={{ 'minWidth': '250px' }}>
															<AsyncSelect
																key={index}
																value={item.equipment}
																defaultOptions
																loadOptions={getEquipment}
																placeholder="Select Equipment"
																onChange={onEquipmentChange(index)}
																menuPosition="fixed"
															/>
														</td>
														<td>
															{item.itemName}
														</td>
														<td>
															{item.model}
														</td>
														<td>
															{item.capCode}
														</td>
														<td>
															{item.emoDate}
														</td>
														<td>
															{item.receiveDate}
														</td>
														<td style={{ 'minWidth': '250px' }}>
															<AsyncSelect
																value={item.location}
																defaultOptions
																loadOptions={getProjectLocation}
																placeholder="Select"
																onChange={onProjectLocationChange(index)}
																styles={customStylesSelect}
															/>
														</td>
														
														<td>
															<div className="col-lg-12" style={{ width: "max-content" }}>
																<textarea className="form-control" name="remark" id="remark" value={item.remark} onChange={onremarkChange(index)} />
															</div>
														</td>

														<td>
															<div className="row">
																<div className="col-2">
																	{items.length > 1 ? <button type="button" className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={()=>removeItem(index)}> <span className="svg-icon svg-icon-md svg-icon-danger">
																		<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
																	</span></button> : ''}
																</div>
															</div>
														</td>
													</tr>
												)
											}

											<tr>
												<td colSpan="11">
													<div className="form-group row">
														<div className="col-lg-2">
                                                        <button 
                                                            type="button" 
                                                            className="btn btn-outline-primary btn-sm" 
                                                            onClick={() => addItem()}>
                                                            Add New
                                                        </button>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							

							<div className="card-footer">
								<div className="row">
									<div className="col-4">
									</div>
									<div className="col-6">
										<button type="submit" className="btn btn-success mr-2">Submit</button>
										<button type="reset" className="btn btn-secondary">Cancel</button>
									</div>
								</div>
							</div>
							{/* <pre>
								{JSON.stringify(items, null, 2)}
							</pre> */}
						</div>
					</form>
				</div>

				{/* <ToastContainer /> */}
			</>
		);
	}


export default EquipmentBuffer;
