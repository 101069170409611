import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getGetRequestOptions } from '../components/GetToken'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../_metronic/_helpers'
import parse from 'html-react-parser'

class SrrConfirm extends Component {
	state = {
		equipmentId: '',
		operationIndex: '',
		selectedSorId: [],
		serviceOrderReceive: [],
		serviceOrderReceiveList: ''
	}

	componentDidMount() {
		if (this.props.equipmentId !== undefined) {
			this.setState({ equipmentId: this.props.equipmentId })
			if (this.props.selectedSorId !== undefined) {
				this.setState({
					selectedSorId: this.props.selectedSorId,
					operationIndex: this.props.operationIndex
				})
			}
			this.getServiceOrderReceiveList(
				this.props.equipmentId,
				this.props.selectedSorId,
				this.props.operationIndex,
				this.props.srrType,
				this.props.moId !== undefined ? this.props.moId : ''
			)
		}
	}

	getServiceOrderReceiveList(equipmentId, selectedSorId, operationIndex, srrType, moId) {
		if (srrType === 'create') {
			var url =
				process.env.REACT_APP_API_URL +
				'maintenance_order/service_order_receive_list/' +
				equipmentId
		} else {
			url =
				process.env.REACT_APP_API_URL +
				'maintenance_order/service_order_receive_list_edit/' +
				equipmentId +
				'/' +
				moId
		}
		fetch(url, getGetRequestOptions())
			.then(resp => {
				return resp.json()
			})
			.then(resp => {
				var selectedId = selectedSorId[operationIndex]
				let selectedSorIdArr = []
				selectedSorIdArr = selectedSorId.filter(item => item !== selectedId)
				const srrResult = resp.filter(
					item => !selectedSorIdArr.includes(item.serviceOrderReceiveId)
				)
				const serviceOrderReceiveList = srrResult.map(function (row) {
					if (row.serviceOrderReceiveId === selectedId) {
						row.isSelected = 1
					} else {
						row.isSelected = 0
					}
					return row
				})
				this.setState({ serviceOrderReceiveList: serviceOrderReceiveList })
			})
			.catch(error => {
				console.log(error, 'catch the hoop')
			})
	}

	onSelectChange = (index, selected) => {
		var serviceOrderReceive = []
		const newItems = this.state.serviceOrderReceiveList.map(function (
			row,
			rindex
		) {
			if (selected === 0) {
				if (index === rindex) {
					if (row.isSelected === 0) {
						row.isSelected = 1
						serviceOrderReceive = row
					}
				} else {
					row.isSelected = 0
				}
			} else {
				row.isSelected = 0
				serviceOrderReceive = []
			}
			return row
		})
		this.setState(
			{
				serviceOrderReceiveList: newItems,
				serviceOrderReceive: serviceOrderReceive
			},
			() =>
				this.props.onSrrSelected({ serviceOrderReceive: serviceOrderReceive })
		)
	}

	render() {
		return (
			<>
				<h3 className='card-title'>
					<p className='text-primary'>Service Order Receive List</p>
				</h3>

				<form onSubmit={this.handleSubmit}>
					<div className='col-12'>
						<div className='table-responsive'>
							<table className='table table-bordered table-hover'>
								<thead>
									<tr>
										<th>Select</th>
										<th>SO SN</th>
										<th>Tagged Requisition</th>
										<th>Service Order Id</th>
										<th>SRC ID</th>
										<th>Item Name</th>
										<th>Quantity</th>
										<th>Rate</th>
										<th>Amount</th>
										<th>Vendor Name</th>
									</tr>
								</thead>
								{this.state.serviceOrderReceiveList.length !== 0 ? (
									<tbody>
										{this.state.serviceOrderReceiveList.map((item, index) => (
											<tr key={index}>
												<td
													style={{ padding: '0.75rem', textAlign: 'center' }}
													onClick={() =>
														this.onSelectChange(index, item.isSelected)
													}
												>
													{item.isSelected === 1 && (
														<span className='svg-icon svg-icon-3x svg-icon-success'>
															<SVG
																src={toAbsoluteUrl(
																	'/media/svg/icons/Navigation/Check.svg'
																)}
															/>
														</span>
													)}

													{item.isSelected === 0 && (
														<span className='svg-icon svg-icon-3x svg-icon-danger'>
															<SVG
																src={toAbsoluteUrl(
																	'/media/svg/icons/Navigation/Close.svg'
																)}
															/>
														</span>
													)}
												</td>
												<td>{item.serviceOrderSn}</td>
												<td>{item.requisitionId}</td>
												<td style={{ whiteSpace: 'nowrap' }}>
													{parse(item.serviceOrderId)}
												</td>
												<td style={{ whiteSpace: 'nowrap' }}>{item.srrNo}</td>
												<td style={{ whiteSpace: 'nowrap', fontSize: "smaller" }}>
													{parse(item.categoryName)}
												</td>
												<td style={{ whiteSpace: 'nowrap', fontSize: "smaller" }}>
													{parse(item.quantity)}
												</td>
												<td style={{ whiteSpace: 'nowrap', fontSize: "smaller" }}>
													{parse(item.rate)}
												</td>
												<td style={{ whiteSpace: 'nowrap', fontSize: "smaller" }}>
													{parse(item.totalAmount)}
												</td>
												<td>{item.vendorName}</td>
											</tr>
										))}
									</tbody>
								) : (
									<tbody>
										<tr>
											<td colSpan='9'>
												<p style={{ textAlign: 'center' }}>
													<strong>No Srr found for this equipment</strong>
												</p>
											</td>
										</tr>
									</tbody>
								)}
							</table>
						</div>
					</div>
					{/* <pre>
						{JSON.stringify(this.state, null, 2)}
					</pre> */}
				</form>

				<ToastContainer />
			</>
		)
	}
}

export default SrrConfirm
