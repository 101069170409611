import React, { Component } from 'react';
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getGetRequestOptions } from "../components/GetToken";
import SrrConfirm from '../components/SrrConfirm';
import { AppContext } from '../context/AppContext';

const UserSingleValue = ({ children, ...props }) => (
	<components.SingleValue {...props}>
		<span>{props.data.fullName}</span>
	</components.SingleValue>
)
const PersonMultiValue = ({ children, ...props }) => (
	<components.MultiValue {...props}>
		<span>{props.data.employeeName}</span>
	</components.MultiValue>
);

class MaintenanceOrderEdit extends Component {
	static contextType = AppContext;
	constructor(props) {
		super(props);
		this.state = {
			orderId: "",
			businessUnit: "",
			maintBusinessUnit: "",
			title: "",
			maintenanceType: "",
			equipmentCondition: "breakdown",
			breakdown: true,
			running: false,
			priority: "",
			equipment: "",
			closingHrsMeter: "",
			responsiblePersons: [],
			orderDate: "",
			approver: "",
			selectedSorId: [],
			operationIndex: "",
			parts: [],
			operations: [],
			totalOperationsCost: "",
			totalCost: "",
			allBusinessUnits: [],
			warehouseOptions: [],

		}
	}


	componentDidMount() {
		const {
			params: { orderId }
		} = this.props.match;
		this.setState({ orderId: this.props.match.params.orderId });
		this.getAllBu();
		this.getMainType();
		this.getMaintenanceEditInfoById(orderId);
	}

	getAllBu() {
		fetch(process.env.REACT_APP_API_URL + "get_business_units",
			//fetch(process.env.REACT_APP_API_URL + "get_receiving_business_unit",
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let tempResp = resp.data.map(function (element) {
					return { ...element, label: element.projectName, value: element.projectId, isdisabled: element.projectName === 'EMD' ? false : true }
				});
				this.setState({ allBusinessUnits: tempResp });
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	getMainType() {
		fetch(process.env.REACT_APP_API_URL + "maintenance_type",
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				let tempResp = resp.data.map(function (element) {
					return { ...element, label: element.typeName, value: element.typeId }
				});
				this.setState({ allMaintType: tempResp });
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	getMaintenanceEditInfoById(orderId) {
		fetch(process.env.REACT_APP_API_URL + "maintenance_order/edit_info/" + orderId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {

				let approver = null;
				if (resp.approver !== null) {
					approver = { ...resp.approver, label: `${resp.approver.fullName}  ${resp.approver.email}`, value: resp.approver.id_users };
				}

				this.setState({
					reservationId: resp.reservationId,
					businessUnit: resp.businessunit,
					maintBusinessUnit: resp.maint_businessunit,
					title: resp.title,
					maintenanceType: resp.maintenancetype,
					priority: resp.priority,
					equipment: resp.equipment,
					closingHrsMeter: resp.closingHrsMeter,
					responsiblePersons: resp.responsibleperson,
					orderDate: resp.orderDate,
					parts: resp.parts,
					operations: resp.operations,
					totalOperationsCost: resp.totalOperationsCost,
					totalCost: resp.totalCost,
					selectedSorId: resp.selectedSorId,
					equipmentCondition: resp.equipmentCondition,
					breakdown: resp.equipmentCondition === 'breakdown' ? true : false,
					running: resp.equipmentCondition === 'running' ? true : false,
					approver: approver
				}, () => this.getBuWarehouse(this.state.businessUnit.projectId), () => this.calcTotal());
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	getEquipment = (inputValue, callback) => {
		const projectId = this.state.businessUnit.projectId;
		if (!inputValue) {
			var url = process.env.REACT_APP_API_URL + "equipment/equipment_search/" + projectId;

		}
		else {
			url = process.env.REACT_APP_API_URL + "equipment/equipment_search/" + projectId + "?q=" + inputValue;
		}
		setTimeout(() => {
			fetch(url, getGetRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						element.label = `${element.equipmentName} (${element.equipmentCode})`;
						element.value = element.equipmentId;
						return element;
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	getResPersons = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "get_employee";
		} else {
			api = process.env.REACT_APP_API_URL + "get_employee?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getGetRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.employeeId, label: `${element.employeeName} ${element.employeeCode}`, value: element.employeeId }
					});

					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	getBuWarehouse = (businessUnitId) => {
		fetch(process.env.REACT_APP_API_URL + "get_warehouse_bu_wise/" + businessUnitId,
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				this.setState({ warehouseOptions: resp.data });
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}

	getAllCategories = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "get_all_categories";
		} else {
			api = process.env.REACT_APP_API_URL + "get_all_categories?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getGetRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, label: element.categoryName, value: element.categoryId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	getApprover = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "user_search";
		} else {
			api = process.env.REACT_APP_API_URL + "user_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getGetRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.idUsers, label: `${element.fullName} ${element.email}`, value: element.idUsers }
					});

					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}

	handleBusinessUnitChange = businessUnit => {
		this.setState({ businessUnit: businessUnit }, () => this.getBuWarehouse(this.state.businessUnit.projectId));
	}

	handleMaintBuChange = maintBusinessUnit => {
		this.setState({ maintBusinessUnit: maintBusinessUnit }, () => {
		});
	}

	handleInputOnChange = evt => {
		this.setState({ [evt.target.name]: evt.target.value });
	};

	handleMaintenanceTypeChange = (maintenanceType) => {
		this.setState({ maintenanceType: maintenanceType });
	}

	handleEquipmentChange = equipment => {
		this.setState({ equipment: equipment });
	}

	handleResponsiblePersonsChange = (persons) => {
		this.setState({ responsiblePersons: persons });
	}

	handleApproverChange = (approver) => {
		this.setState({ approver: approver });
	}

	onWarehouseChange = (index) => evt => {
		const newItems = this.state.parts.map(function (row, rindex) {
			if (index !== rindex) {
				return row;
			}
			else {
				return { ...row, warehouse: evt.target.value }
			}
		});
		this.setState({ parts: newItems });
	}

	handleEquipmentConditionChange = evt => {
		if (evt.target.name === "breakdown" && evt.target.checked === true) {
			this.setState({
				breakdown: evt.target.checked,
				running: false,
				equipmentCondition: "breakdown",

			});
		}
		else if (evt.target.name === "breakdown" && evt.target.checked === false) {
			this.setState({
				breakdown: evt.target.checked,
				running: true,
				equipmentCondition: "running",
			});
		}
		else if (evt.target.name === "running" && evt.target.checked === true) {
			this.setState({
				running: evt.target.checked,
				breakdown: false,
				equipmentCondition: "running",
			});
		}
		else {
			this.setState({
				running: evt.target.checked,
				breakdown: true,
				equipmentCondition: "breakdown",
			});
		}
	}

	onMaterialNameChange = index => evt => {
		const newItems = this.state.parts.map(function (row, rindex) {
			if (index === rindex) {
				row.materialName = evt.target.value;
			}
			return row;
		});
		this.setState({ parts: newItems });
	}

	onMaterialDescChange = (index) => evt => {
		const newItems = this.state.parts.map(function (row, rindex) {
			if (index === rindex) {
				row.materialDesc = evt.target.value;
			}
			return row;
		})
		this.setState({ parts: newItems });

	}


	addPartsItem = () => {
		this.setState({
			parts: this.state.parts.concat([{ warehouse: this.state.parts[0] ? this.state.parts[0].warehouse : "", qty: "", materialName: "", materialDesc: "", invStatus: "" }])
		});
	}


	removePartsItem = (index) => () => {
		this.setState({
			parts: this.state.parts.filter((row, rindex) => index !== rindex)
		});
	}

	onPartsQtyChange = (index) => evt => {
		const newItems = this.state.parts.map(function (row, rindex) {
			if (index === rindex) {
				row.qty = evt.target.value;
			}
			return row;
		})

		this.setState({ parts: newItems });
	}

	objPsum = (items, prop) => {
		return items.reduce(function (a, b) {
			return Number(a) + Number(b[prop]);
		}, 0);
	}
	calcTotal = () => {
		const totalOperationsCost = this.objPsum(this.state.operations, 'amount');
		const totalCost = Number(totalOperationsCost);
		this.setState({ totalOperationsCost: (totalOperationsCost).toFixed(2) });
		this.setState({ totalCost: (totalCost).toFixed(2) });
	}

	/*  operation  function*/

	onOperationChange = (index) => evt => {

		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.operation = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems });

	}

	onOperationDetailsChange = (index) => evt => {

		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.operationDetails = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems });

	}

	onOperationDateChange = (index) => evt => {

		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.startDate = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems });

	}


	onOperationTimeChange = (index) => evt => {

		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.time = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems });

	}

	onOperationInputChange = (index, input) => evt => {

		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {

				row[input] = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems });

	}

	onOperationAmountChange = index => evt => {
		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.amount = evt.target.value;
			}
			return row;
		})

		this.setState({ operations: newItems }, () => this.calcTotal());

	}

	addOperationItem = () => {
		this.setState({
			operations: this.state.operations.concat([{ operation: "", operationDetails: "", startDate: "", endDate: "", amount: "", time: "", isComplete: "no", serviceOrderReceive: [], hasNoSrr: '0', isSrrConfirm: "" }])
		});
		this.calcTotal();
	}

	removeOperationItem = (index) => () => {
		this.setState({
			operations: this.state.operations.filter((row, rindex) => index !== rindex),
			selectedSorId: this.state.selectedSorId.filter((item, i) => index !== i)
		}, () => this.calcTotal());
	}

	confirmSrr = (index, equipmentId) => {
		this.setState({ srrConfirmModalShow: true, equipmentId: equipmentId, operationIndex: index });
	}

	onSrrConfirm = (confirmData) => {
		this.setState({ srrConfirmModalShow: false });
	}

	onHasSrrChange = (index) => evt => {
		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.hasNoSrr = evt.target.checked === true ? "1" : "0";
			}
			return row;
		})

		this.setState({ operations: newItems });
	}

	onSrrSelected = (serviceOrder) => {
		var index = this.state.operationIndex;
		var selectedSorId = this.state.selectedSorId;
		const newItems = this.state.operations.map(function (row, rindex) {
			if (index === rindex) {
				row.serviceOrderReceive = serviceOrder.serviceOrderReceive;
				row.amount = serviceOrder.serviceOrderReceive.length !== 0 ? serviceOrder.serviceOrderReceive.grandTotalAmount : "";
				row.operation = serviceOrder.serviceOrderReceive.length !== 0 ? serviceOrder.serviceOrderReceive.serviceName : "";
				row.startDate = serviceOrder.serviceOrderReceive.length !== 0 ? serviceOrder.serviceOrderReceive.startDate : "";
				row.endDate = serviceOrder.serviceOrderReceive.length !== 0 ? serviceOrder.serviceOrderReceive.endDate : "";
			}
			return row;
		})
		selectedSorId[index] = serviceOrder.serviceOrderReceive.length !== 0 ? serviceOrder.serviceOrderReceive.serviceOrderReceiveId : "";
		this.setState({ operations: newItems, selectedSorId: selectedSorId });
		this.calcTotal();
	}

	handleSubmit = evt => {
		evt.preventDefault();
		Swal.fire({
			title: 'Are you sure?',
			text: "You want to update Maintenance Order!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No'
		}).then((result) => {
			if (result.value) {
				const jwt = this.context.jwt.token;
				const formData = {
					orderId: this.state.orderId,
					reservationId: this.state.reservationId,
					businessUnit: this.state.businessUnit,
					maintBusinessUnit: this.state.maintBusinessUnit,
					title: this.state.title,
					maintenanceType: this.state.maintenanceType,
					priority: this.state.priority,
					equipment: this.state.equipment,
					closingHrsMeter: this.state.closingHrsMeter,
					responsiblePersons: this.state.responsiblePersons,
					orderDate: this.state.orderDate,
					parts: this.state.parts,
					operations: this.state.operations,
					totalOperationsCost: this.state.totalOperationsCost,
					totalCost: this.state.totalCost,
					equipmentCondition: this.state.equipmentCondition,
					approver: this.state.approver
				};
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
					body: JSON.stringify(formData)
				};

				var apiEnd = "maintenance_order/update/" + this.state.orderId;

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp);

						if (resp.success === true) {
							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: 'Maintenance Order has been updated successfully!',
							})

							this.setState(this.baseState);
							this.props.history.push(`/maintenance-order-details/${resp.data.orderId}`);
						}
						else {
							var errorsMessage = [];

							if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
								var errorsObj = resp.errorMessage;
								Object.keys(errorsObj).forEach(function (value) {
									errorsObj[value].forEach(function (v) {
										errorsMessage.push(v)
									});

								});

							} else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
								errorsMessage.push(resp.errorMessage);
							} else {
								errorsMessage.push("Something went wrong");
							}
							Swal.fire({
								icon: 'error',
								title: resp.heading,
								text: errorsMessage,
							})
						}


					})
					.catch((error) => {
						console.log(error, "catch the hoop")
					});
			}
		})
	}


	render() {
		const imagehost = process.env.REACT_APP_PAYROLL_IMAGE + 'employee/personal/thumb/';
		const priorityOption = [
			{ label: 'High', value: 'high' },
			{ label: 'Medium', value: 'medium' },
			{ label: 'Low', value: 'low' },
		]

		let srrConfirmModalClose = () => {
			this.setState({ srrConfirmModalShow: false });
		};

		return (
			<>
				<div className="card card-custom">
					<div className="card-header">
						<h3 className="card-title">
							<p className="text-primary">
								Maintenance Order Edit
							</p>
						</h3>
					</div>

					<form onSubmit={this.handleSubmit}>
						<div className="card-body">
							<div className="ml-12">
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-lg-2 col-form-label">Business Unit </label>
									<div className="col-lg-6">
										<Select
											value={this.state.businessUnit}
											onChange={this.handleBusinessUnitChange}
											isDisabled={true}
											options={this.state.allBusinessUnits}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-2 col-form-label">Title</label>
									<div className="col-6">
										<input className="form-control form-control-sm" type="text" name="title" id="title" value={this.state.title || ""} onChange={this.handleInputOnChange} />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-lg-2 col-form-label">Maintenance Type</label>
									<div className="col-lg-6">
										<Select
											value={this.state.maintenanceType}
											onChange={this.handleMaintenanceTypeChange}
											options={this.state.allMaintType}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-lg-2 col-form-label">Equipment Condition<span className="required text-danger"> *</span></label>
									<div className="col-lg-6">
										<div className="checkbox-inline">
											<label className="checkbox checkbox-lg">
												<input type="checkbox"
													name="breakdown"
													checked={this.state.breakdown}
													onChange={this.handleEquipmentConditionChange}
												/>
												<span></span>
												Breakdown
											</label>
											<label className="checkbox checkbox-lg">
												<input type="checkbox" name="running"
													checked={this.state.running}
													onChange={this.handleEquipmentConditionChange}
												/>
												<span></span>
												Running
											</label>
										</div>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-2 col-form-label">Priority</label>
									<div className="col-lg-6">
										<select className="form-control form-control-sm" id="priority" name="priority" value={this.state.priority} onChange={this.handleInputOnChange}>
											{priorityOption.map(function (item, id) {
												return <option key={id} value={item.value}>{item.label}</option>
											})
											}
										</select>
									</div>
								</div>

								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-lg-2 col-form-label">Equipment<span className="required text-danger"> *</span></label>
									<div className="col-lg-6">
										<AsyncSelect
											value={this.state.equipment}
											defaultOptions
											loadOptions={this.getEquipment}
											placeholder="Select Equipment"
											onChange={this.handleEquipmentChange}
											isDisabled={true}
										/>
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-2 col-form-label">Closing Hrs Meter</label>
									<div className="col-6">
										<input className="form-control form-control-sm" type="text" name="closingHrsMeter" id="closingHrsMeter" value={this.state.closingHrsMeter || ""} onChange={this.handleInputOnChange} />
									</div>
								</div>
								<div className="form-group row">
									<label className="col-lg-2 col-form-label" >Responsible Persons</label>
									<div className="col-lg-6">
										<AsyncSelect
											value={this.state.responsiblePersons}
											isMulti
											defaultOptions
											loadOptions={this.getResPersons}
											placeholder="Select Responsible Persons"
											onChange={this.handleResponsiblePersonsChange}
											components={{ MultiValue: PersonMultiValue }}
											getOptionLabel={(option) => (
												<>
													<div className='row' >
														<div>
															{option.avatar !== "" ?
																<img src={`${imagehost}${option.avatar}`} className='img-fluid' alt='Responsible Person' style={{ width: "70px" }} />
																:
																<img src={toAbsoluteUrl("/media/users/blank.png")} className='img-fluid' alt='Responsible Person' style={{ width: "70px" }} />
															}
														</div>
														<div style={{ marginLeft: "5px" }}>
															<span className="svg-icon svg-icon-md svg-icon-primary">
																<SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
																<span> Name: {option.employeeName} </span><br></br>
															</span>
															<span className="svg-icon svg-icon-md svg-icon-danger">
																<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
																<span> Workstation: {option.workStationName} </span><br></br>
															</span>
															<span className="svg-icon svg-icon-md svg-icon-warning">
																<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Color-profile.svg")} />
																<span> Designation: {option.designationTitle}</span>
															</span>
														</div>

													</div>

												</>
											)}

										/>
									</div>
								</div>

								<div className="form-group row">
									<label htmlFor="orderDate" className="col-lg-2 col-form-label">Order Date<span className="required text-danger"> *</span></label>
									<div className="col-lg-6">
										<input className="form-control form-control-md" type="date" name="orderDate" id="orderDate" value={this.state.orderDate} onChange={this.handleInputOnChange} />
									</div>
								</div>
								<div className="form-group row">
									<label htmlFor="example-text-input" className="col-lg-2 col-form-label"> Equipment Location BU <span className="required text-danger"> *</span></label>
									<div className="col-lg-6">
										<Select
											value={this.state.maintBusinessUnit}
											onChange={this.handleMaintBuChange}
											options={this.state.allBusinessUnits}

										/>
									</div>
								</div>
								<div className="form-group row">
									<label className="col-lg-2 col-form-label" > Approver <span className="required text-danger"> *</span></label>
									<div className="col-lg-6">
										<AsyncSelect
											value={this.state.approver}
											components={{ SingleValue: UserSingleValue }}
											defaultOptions
											loadOptions={this.getApprover}
											placeholder="Select Approver"
											onChange={this.handleApproverChange}
											menuPosition="fixed"
											getOptionLabel={(option) => (
												<>
													<div className='row' style={{ marginRight: '0px' }}>
														<div>
															{option.avatar !== "" ?
																<img src={`${imagehost}${option.fileName}`} className='img-fluid' alt='Approver' style={{ width: "70px" }} />
																:
																<img src={toAbsoluteUrl("/media/users/blank.png")} className='img-fluid' alt='Approver' style={{ width: "70px" }} />
															}
														</div>
														<div style={{ marginLeft: "5px" }}>
															<span className="svg-icon svg-icon-md svg-icon-primary">
																<SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
																<span> Name: {option.fullName} </span><br></br>
															</span>
															<span className="svg-icon svg-icon-md svg-icon-danger">
																<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
																<span>Designation: {option.designation} </span><br></br>
															</span>
															<span className="svg-icon svg-icon-md svg-icon-warning">
																<SVG src={toAbsoluteUrl("/media/svg/icons/Design/Color-profile.svg")} />
																<span> Email: {option.email}</span>
															</span>
														</div>

													</div>

												</>
											)}

										/>
									</div>
								</div>

							</div>


							<div className="mt-10">
								<Tabs defaultActiveKey="parts" id="uncontrolled-tab-example">
									<Tab eventKey="parts" title="Parts">

										<div className="table-responsive">
											<table className="table table-bordered table-hover" style={{ "minWidth": "max-content" }}>
												<thead>
													<tr>
														<th>SL</th>
														<th>Warehouse</th>
														<th>Material Name</th>
														<th>Material Description</th>
														<th>Estimated Quantity</th>
														<th>Inv Status</th>
														<td>Action</td>
													</tr>
												</thead>
												<tbody>
													{
														this.state.parts.map((item, index) =>
															<tr key={index}>
																<td>{index + 1}</td>
																<td>
																	<select className="form-control" onChange={this.onWarehouseChange(index)} value={item.warehouse} disabled={item.invStatus === 'booked' || item.invStatus === 'issued'} >
																		<option value="">Select Warehouse</option>
																		{
																			this.state.warehouseOptions.map(function (item, id) {
																				return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
																			})
																		}
																	</select>
																</td>
																<td>
																	<input type="text" value={item.materialName} className="form-control" onChange={this.onMaterialNameChange(index)} readOnly={item.invStatus === 'booked' || item.invStatus === 'issued'} />
																</td>
																<td><textarea className="form-control" value={item.materialDesc} onChange={this.onMaterialDescChange(index)} readOnly={item.invStatus === 'booked' || item.invStatus === 'issued'} /></td>
																<td><input className="form-control" style={{ 'marginTop': '0px' }} type="number" value={item.qty} onChange={this.onPartsQtyChange(index)} readOnly={item.invStatus === 'booked' || item.invStatus === 'issued'} /></td>
																<td>
																	{item.invStatus === "booked" && <span className={'label label-lg label-primary label-inline mr-2'}>Booked</span>}
																	{item.invStatus === "issued" && <span className={'label label-lg label-success label-inline mr-2'}>Issued</span>}
																</td>
																<td>
																	{(item.invStatus === "" || item.invStatus === null) &&
																		<div className="row">
																			<div className="col-2">
																				<button type="button" className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={this.removePartsItem(index)}>
																					<span className="svg-icon svg-icon-md svg-icon-danger">
																						<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
																					</span>
																				</button>
																			</div>
																		</div>
																	}
																</td>
															</tr>
														)
													}
													<tr>
														<td colSpan="12">
															<div className="form-group row">
																<div className="col-lg-2">
																	<button type="button" className="btn btn-primary btn-sm" onClick={this.addPartsItem}>Add Parts</button>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</Tab>

									<Tab eventKey="operations" title="Operations" >
										<div className="table-responsive">
											<table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
												<thead>
													<tr>
														<th>SL</th>
														<th>Operation</th>
														<th>Operation Short Desc</th>
														<th>Start Date</th>
														<th>End Date</th>
														{/* <th>Duration(h:m)</th> */}
														<th>Tag SR Certification</th>
														<th>Amount</th>
														<th>SRC Confirm</th>
														<th>Is Complete</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>
													{
														this.state.operations.map((item, index) =>
															<>
																<tr key={index}>
																	<td rowSpan={2}>{index + 1}</td>
																	<td><input className="form-control" type="text" value={item.operation || ""} onChange={this.onOperationChange(index)} readOnly={item.isComplete === "yes"} /></td>
																	<td><textarea className="form-control" value={item.operationDetails || ""} onChange={this.onOperationDetailsChange(index)} readOnly={item.isComplete === "yes"} /></td>
																	<td><input className="form-control" type="date" value={item.startDate || ""} onChange={this.onOperationDateChange(index)} readOnly={item.isComplete === "yes"} /></td>
																	<td><input className="form-control" type="date" value={item.endDate || ""} onChange={this.onOperationInputChange(index, 'endDate')} readOnly={item.isComplete === "yes"} /></td>
																	{/* <td>
																		<InputMask mask="9999:99" className="form-control" onChange={this.onOperationTimeChange(index)} value={item.time} style={{ "width": "40%" }} />
																	</td> */}
																	<td>
																		<div className="form-group">
																			<div className="input-group">
																				<input type="text" className="form-control" value={item.serviceOrderReceive.length !== 0 ? item.serviceOrderReceive.srrNo : ""} placeholder="Srr no" aria-describedby="basic-addon2" readOnly />
																				<div className="input-group-append">
																					<button type="button" onClick={() => this.confirmSrr(index, this.state.equipment.equipmentId)} style={{ 'marginTop': '0px' }} className="pt-2 btn btn-primary btn-sm" disabled={item.isComplete === "yes" || item.hasNoSrr === '1' || item.isSrrConfirm === 'yes'}><i className="flaticon2-tag"></i></button>
																				</div>
																			</div>
																		</div>

																	</td>
																	<td><input className="form-control" type="number" value={item.amount || ""} onChange={this.onOperationAmountChange(index)} readOnly /></td>
																	{item.isSrrConfirm === "yes" && <td>Yes</td>}
																	{item.isSrrConfirm === "no" && <td>No</td>}
																	{item.isSrrConfirm === "" && <td></td>}
																	{item.isSrrConfirm === null && <td></td>}
																	<td>
																		<span className={item.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{item.isComplete === 'no' ? 'No' : 'Yes'}</span>
																	</td>
																	<td>
																		<div className="row">
																			<div className="col-2">
																				{item.isComplete === "no" &&
																					<button type="button" className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={this.removeOperationItem(index)} disabled={item.isSrrConfirm === "yes"}>
																						<span className="svg-icon svg-icon-md svg-icon-danger">
																							<SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
																						</span>
																					</button>
																				}
																			</div>
																		</div>
																	</td>
																</tr>
																<tr>
																	<td colSpan={8}>
																		<div className="row col-lg-6">
																			<div className="checkbox-inline">
																				<label className="checkbox checkbox-lg">
																					<input type="checkbox"
																						name="hasNoSrr"
																						checked={item.hasNoSrr === '1'}
																						onChange={this.onHasSrrChange(index)}
																						disabled={item.isComplete === "yes" || item.isSrrConfirm === 'yes'}
																					/>
																					<span></span>
																					Has No SR Certification
																				</label>
																			</div>
																		</div>
																		<span className="text-muted font-weight-bold"> If this operation has no SR Certifaction click the box</span>
																	</td>
																</tr>
															</>
														)
													}
													<tr>
														<td colSpan="10">
															<div className="form-group row">
																<div className="col-lg-2">
																	<button type="button" className="btn btn-primary btn-sm" onClick={this.addOperationItem}>Add Operation</button>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
												<tfoot>
													<tr>
														<td colSpan="7" className="kt-align-right kt-font-bold" style={{ "textAlign": "right" }}>
															Total
														</td>
														<td className="kt-align-right kt-font-brand kt-font-bold">{this.state.totalOperationsCost}</td>
														<td></td>
													</tr>
												</tfoot>
											</table>
										</div>
									</Tab>
								</Tabs>
								<div className="col-md-4 offset-md-8 mt-10">
									<div className="row">
										<div className="col-md-6"><b>Total Operations Cost</b></div>
										<div className="col-md-3">{this.state.totalOperationsCost}</div>
									</div>
									<div className="row">
										<div className="col-md-6"><b>Total Cost</b></div>
										<div className="col-md-3">{this.state.totalCost}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="card-footer">
							<div className="row">
								<div className="col-4">
								</div>
								<div className="col-6">
									<button type="submit" className="btn btn-success mr-2">Submit</button>
									<button type="reset" className="btn btn-secondary">Cancel</button>
								</div>
							</div>
						</div>
						{/* <pre>
							{JSON.stringify(this.state, null, 2)}
						</pre> */}

						<Modal size="lg" show={this.state.srrConfirmModalShow} onHide={srrConfirmModalClose} aria-labelledby="example-modal-sizes-title-lg">
							<Modal.Body>
								<SrrConfirm onSrrConfirm={this.onSrrConfirm} onSrrSelected={this.onSrrSelected} equipmentId={this.state.equipment.equipmentId} selectedSorId={this.state.selectedSorId} operationIndex={this.state.operationIndex} srrType={'edit'} moId={this.state.orderId} />
							</Modal.Body>

							<Modal.Footer>
								<Button variant="primary" onClick={srrConfirmModalClose}>Ok</Button>
								<Button variant="secondary" onClick={srrConfirmModalClose}>Cancel</Button>
							</Modal.Footer>
						</Modal>
					</form>
				</div>
				{/* <ToastContainer /> */}
			</>
		);
	}
}

export default MaintenanceOrderEdit;