import React, { Component } from 'react';
import DataTable from '../components/DataTable'
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import ToastMsg from '../components/ToastMsg';
import { AppContext } from '../context/AppContext';
const columns = [
    { 'value': 'id', 'title': 'Id' },
    { 'value': 'cnNo', 'title': 'Credit Note No' },
    { 'value': 'amount', 'title': 'Amount' },
    { 'value': 'billNo', 'title': 'Bill No' },
    { 'value': 'billTo', 'title': 'Bill BU' },
    { 'value': 'status', 'title': 'Status' },
    {
        'value': 'action', title: 'Action', actions: [
            { link: 'generate-bill-list-credit-note-details', calAction: false },
            { link: 'credit-note-approve', calAction: true, isApproveBtn: true }
        ]
    }
];

const filters = {
    'id': {
        filterType: 'like',
        filterValue: ''
    },
    'billNo': {
        filterType: 'like',
        filterValue: ''
    },
    'cnNo': {
        filterType: 'like',
        filterValue: ''
    },
    'amount': {
        filterType: 'like',
        filterValue: ''
    },
    'billTo': {
        filterType: 'like',
        filterValue: ''
    },
    'status': {
        filterType: 'like',
        filterValue: ''
    }

};

const url = process.env.REACT_APP_API_URL + "credit_note_list";


class CreditNoteList extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = { newData: "", name: "" }
    }
    onCreate = ({ id, name }) => {
        this.setState({ newMake: { id: id, name: name } });
    }
    onAction = ({ id, link, cnNo }) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#C1C1C1',
            confirmButtonText: 'Yes, approve it!'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = { creditNoteId: id };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "credit_note_approve", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {

                            toast.success(<ToastMsg toastMessage={[resp.successMessage]} heading="Credit Note approved successfully" />, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            this.setState({ newData: { id: id, name: cnNo } });

                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })

    }
    render() {
        return (
            <div className="card card-custom">


                <div className="card-header">
                    <h3 className="card-title"><p className="text-primary">Credit Note List</p></h3>
                </div>
                <div className="card-body">
                    <DataTable url={url} columns={columns} filters={filters} newData={this.state.newData} onAction={this.onAction}>
                        <Button className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
                            </span>
                        </Button>
                        <>
                            Approve
                        </>
                    </DataTable>

                </div>

            </div >

        );
    }
}

export default CreditNoteList;