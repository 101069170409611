import React, { useEffect } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGetRequestOptions } from "../components/GetToken";


export default function PaymentWarning() {

	useEffect(() => {
		getPaymentWarningProject();
	}, []);

	const getPaymentWarningProject = () => {

		fetch(process.env.REACT_APP_API_URL + "payment_warning_project",
			getGetRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const result = resp.filter(item => item.projectId === 47);
				if (result.length > 0) {
					showErrorToast();
				}
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
	}


	const showErrorToast = () => {
		toast.error(<p style={{ "color": "#fff" }}><p><b>WARNING: Your business unit's ERP payment is overdue.</b><br /> Your ERP access will be restricted if payment is not cleared soon.Please, contact your superiors or assigned persons to inform them about this payment issue.</p></p>, {
			position: toast.POSITION.TOP_CENTER,
			theme: "colored"
		});
	};

	return (
		<>
			<ToastContainer autoClose={false} style={{ width: "100%" }} />
		</>
	)
}
