import React, { Component } from 'react';
import SVG from "react-inlinesvg";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getGetRequestOptions } from "../components/GetToken";
import { AppContext } from '../context/AppContext';
import InputMask from 'react-input-mask';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};


class RunningHourEntry extends Component {
    static contextType = AppContext;
    state = {
        projectId: "",
        equipment: "",
        operator: "",
        logDate: "",
        startingHrs: "",
        endingHrs: "",
        workSiteName: "",
        majorTasks: "",
        boqItems: "",
        shiftTime: [{ shiftStart: "", shiftEnd: "", hrs: "", mins: "", runningHrs: "" }],
        workType: "",
        workDetails: "",
        shifts: [],
        workTypeList: [],
        workDetailsList: [],
    }

    componentDidMount() {
        const { shifts, ...baseState } = this.state;
        this.baseState = baseState;
    }

    getEquipment = (inputValue, callback) => {

        if (!inputValue) {
            callback([]);

        } else {
            var url = process.env.REACT_APP_API_URL + "equipment/site_engineer_equipment_search?q=" + inputValue;

            setTimeout(() => {
                fetch(url, getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.equipmentName} (${element.equipmentCode})`;
                            element.value = element.equipmentId;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });
        }
    }

    handleEquipmentChange = equipment => {

        this.setState({ equipment: equipment, workTypeList: [], workDetailsList: [], projectId: equipment.projectId, startingHrs: equipment.closingHrsMeter, workType: {}, workDetails: {} }, () => {
            this.getProjectWiseShiftTime(this.state.projectId);
            this.getWorkType(this.state.projectId);
        });
    }

    getWorkType = (projectId) => {

        fetch(process.env.REACT_APP_DASHBOARD_URL + `app/boqItem/task/${projectId}`,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const tempArray = resp.tasks.map(function (element) {
                    element.label = element.taskName;
                    element.value = element.idDashboardEquipmentMajorTasks;
                    return element;
                });

                this.setState({ workTypeList: tempArray });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getWorkDetails = (idDashboardEquipmentMajorTasks) => {

        fetch(process.env.REACT_APP_DASHBOARD_URL + `app/boqItem/${idDashboardEquipmentMajorTasks}`,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const tempArray = resp.boqItems.map(function (element) {
                    element.label = `${element.description} (${element.boqId})`;
                    element.value = element.idDashboardEquipmentBoqItems;
                    return element;
                });

                this.setState({ workDetailsList: tempArray });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getOperatorName = (inputValue, callback) => {
        if (!inputValue) {
            callback([]);

        }
        else {

            var url = process.env.REACT_APP_API_URL + "search_operator?q=" + inputValue;
            setTimeout(() => {
                fetch(url, getGetRequestOptions())
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        const tempArray = resp.data.map(function (element) {
                            element.label = `${element.name} (${element.phone})`;
                            element.value = element.id;
                            element.operatorId = element.id;
                            return element;
                        });
                        callback(tempArray);
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            });

        }
    }

    getProjectWiseShiftTime = (projectId) => {
        fetch(process.env.REACT_APP_API_URL + "emd_project_wise_shift/" + projectId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                const formattedShifts = resp.data.map(shift => ({
                    ...shift,
                    label: `${this.formatTime(shift.shiftStart)} - ${this.formatTime(shift.shiftEnd)}`,
                    value: `${this.formatTime(shift.shiftStart)} - ${this.formatTime(shift.shiftEnd)}`
                }));

                this.setState({ shifts: formattedShifts });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    formatTime = (time) => {
        const [hours, minutes, seconds] = time.split(':');
        const date = new Date();
        date.setHours(hours, minutes, seconds);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    handleOperatorNameChange = operatorName => {
        this.setState({ operator: operatorName });
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleWorkTypeChange = workType => {
        this.setState({ workType: workType, workDetails: {}, workDetailsList: [] }, () => { this.getWorkDetails(workType.value) });
    }

    handleWorkDetailsChange = workDetails => {
        this.setState({ workDetails: workDetails });
    }

    onShiftChange = index => shift => {
        const newItems = this.state.shiftTime.map(function (row, rindex) {
            if (index === rindex) {
                row.shift = shift;
                row.shiftStart = shift.shiftStart;
                row.shiftEnd = shift.shiftEnd;
            }
            return row;
        })
        this.setState({ shiftTime: newItems });
    }

    addItem = () => {
        this.setState({
            shiftTime: this.state.shiftTime.concat([{ shiftStart: "", shiftEnd: "", hrs: "", mins: "", runningHrs: "" }])
        });
    }

    removeItem = (index) => () => {
        this.setState({
            shiftTime: this.state.shiftTime.filter((row, rindex) => index !== rindex)
        });
    }

    handleHourChange = index => evt => {
        const value = evt.target.value;
        if (value > 12) {
            evt.target.value = 12;
        }
        const newItems = this.state.shiftTime.map(function (row, rindex) {
            if (index === rindex) {
                row.hrs = evt.target.value;
                row.runningHrs = `${row.hrs}:${row.mins}`;
            }
            return row;
        })
        this.setState({ shiftTime: newItems });
    }

    handleMinuteChange = index => evt => {
        const value = evt.target.value;
        if (value > 59) {
            evt.target.value = 59;
        }
        const newItems = this.state.shiftTime.map(function (row, rindex) {
            if (index === rindex) {
                row.mins = evt.target.value;
                row.runningHrs = `${row.hrs}:${row.mins}`;
            }
            return row;
        })
        this.setState({ shiftTime: newItems });
    }

    handleSubmit = evt => {
        evt.preventDefault();

        Swal.fire({
            title: 'Are you sure?',
            text: "You want to entry this running hour!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = { ...this.state, idDashboardEquipmentMajorTasks: this.state.workType !== "" ? this.state.workType.value : null, idDashboardEquipmentBoqItems: this.state.workDetails !== "" ? this.state.workDetails.value : null };
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                var apiEnd = "app_log_entry_create";

                fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            let successMsg = resp.successMessage;
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })

                            this.setState(this.baseState);
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                text: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }

    render() {
        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            <p className="text-primary">
                                Running Hour Entry
                            </p>
                        </h3>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className='row'>
                                    <div className='col-2'></div>
                                    <div className='col-8'>
                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Equipment<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <AsyncSelect
                                                    value={this.state.equipment}
                                                    defaultOptions
                                                    loadOptions={this.getEquipment}
                                                    placeholder="Select Equipment"
                                                    onChange={this.handleEquipmentChange}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="currentProject" className="col-lg-2 col-form-label">Current Project<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="text" name="currentProject" id="currentProject" value={this.state.equipment.currentProject || ""} readOnly className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="logDate" className="col-lg-2 col-form-label">Log Date<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="date" name="logDate" id="logDate" value={this.state.logDate} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Operator<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <AsyncSelect
                                                    value={this.state.operator}
                                                    defaultOptions
                                                    loadOptions={this.getOperatorName}
                                                    placeholder="Select Operator"
                                                    onChange={this.handleOperatorNameChange}
                                                    styles={customStylesSelect}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="startingHrs" className="col-lg-2 col-form-label">Starting Hrs Meter<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="number" name="startingHrs" id="startingHrs" value={this.state.startingHrs} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="endingHrs" className="col-lg-2 col-form-label">Ending Hrs Meter<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="number" name="endingHrs" id="endingHrs" value={this.state.endingHrs} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="workSiteName" className="col-lg-2 col-form-label">Work Site Name<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <input type="text" name="workSiteName" id="workSiteName" value={this.state.workSiteName} onChange={this.handleInputOnChange} className={`form-control form-control-md`} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Work Type<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <Select
                                                    value={this.state.workType}
                                                    onChange={this.handleWorkTypeChange}
                                                    placeholder="Select Work Type"
                                                    options={this.state.workTypeList}
                                                    menuPosition="fixed"
                                                    isDisabled={this.state.projectId === ""}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="example-text-input" className="col-lg-2 col-form-label">Work Details<span className="required text-danger"> *</span></label>
                                            <div className="col-lg-6">
                                                <Select
                                                    value={this.state.workDetails}
                                                    onChange={this.handleWorkDetailsChange}
                                                    placeholder="Select Work Details"
                                                    options={this.state.workDetailsList}
                                                    menuPosition="fixed"
                                                    isDisabled={!this.state.workType.idDashboardEquipmentMajorTasks}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-1'></div>
                                <div className='col-9'>
                                    <div className="mt-5">
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Sl.</th>
                                                    <th>Shift</th>
                                                    <th>Running Hour</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.shiftTime.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td style={{ minWidth: '100px' }}>
                                                                <Select
                                                                    value={item.shift}
                                                                    onChange={this.onShiftChange(index)}
                                                                    placeholder="Select Shift"
                                                                    options={this.state.shifts}
                                                                    menuPosition="fixed"
                                                                />
                                                            </td>
                                                            <td style={{ width: "250px" }}>
                                                                <div className="input-group">
                                                                    <InputMask mask="99" className="form-control" name="hrs" id="hrs" placeholder="HH"
                                                                        value={item.hrs}
                                                                        onChange={this.handleHourChange(index)}
                                                                    />
                                                                    <span className='input-group-text'>:</span>
                                                                    <InputMask mask="99" className="form-control" name="mins" id="mins" placeholder="MM"
                                                                        value={item.mins}
                                                                        onChange={this.handleMinuteChange(index)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="row">
                                                                    <div className="col-2">
                                                                        {this.state.shiftTime.length > 1 ? <button type="button" className="btn btn-icon btn-light btn-hover-danger btn-sm" onClick={this.removeItem(index)}> <span className="svg-icon svg-icon-md svg-icon-danger">
                                                                            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                        </span></button> : ''}
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    )
                                                }

                                                <tr>
                                                    <td colSpan="9">
                                                        <div className="form-group row">
                                                            <div className="col-lg-2">
                                                                <button type="button" className="btn btn-outline-primary btn-sm" onClick={this.addItem}>Add New</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="row">
                                <div className="col-4">
                                </div>
                                <div className="col-6">
                                    <button type="submit" className="btn btn-success mr-2">Submit</button>
                                    <button type="reset" className="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                        {/* <pre>
                            {JSON.stringify(this.state, null, 2)}
                        </pre> */}
                    </form>

                </div>

                {/* <ToastContainer /> */}
            </>
        );
    }
}

export default RunningHourEntry;