import React, { Component } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGetRequestOptions } from "../../components/GetToken";
import ToastMsg from '../../components/ToastMsg';
import "./../../../style/table.css";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { AppContext } from '../../context/AppContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

class EquipmentUtilizationReport extends Component {
    static contextType = AppContext;
    state = {
        businessUnit: "",
        month: "",
        year: "",
        reportData: [],
        showReport: false,
        allBusinessUnits: []
    }


    componentDidMount() {
        const { allBusinessUnits, ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllBu();
    }

    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.projectName, value: element.projectId, isdisabled: element.projectName === 'EMD' ? false : true }
                }).filter(e => e.projectId !== process.env.REACT_APP_EMD_BU_ID);
                this.setState({ allBusinessUnits: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    handleBuChange = businessUnit => {
        this.setState({ businessUnit: businessUnit });
    }

    handleMonthChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    handleYearChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };

    getReportData() {
        this.setState({ loading: true });
        const formData = {
            businessUnit: this.state.businessUnit,
            month: this.state.month,
            year: this.state.year
        };

        //console.log(formData);
        const jwt = this.context.jwt.token;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "reports/equipment_utilization_report", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ loading: false });

                if (resp.success === true) {
                    this.setState({
                        reportData: resp.data
                    });
                }
                else {
                    var errorsMessage = [];

                    if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                            });

                        });

                    } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        errorsMessage.push("Something went wrong");
                    }
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error, "catch the hoop")
            });
    }

    handleSubmit = evt => {
        evt.preventDefault();
        this.setState({ showReport: true });
        this.getReportData();
    }

    render() {
        const excelExportEndPoint = process.env.REACT_APP_API_URL.split("api/")[0] + 'report/equipment_utilization_report_excel_export';
        const token = this.context.jwt;

        const yearEndRange = new Date().getFullYear();
        const yearStartRange = yearEndRange - 1;

        const monthOption = [{ label: 'January', value: '1', },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
        ];

        const yearOption = [];
        for (let i = yearStartRange; i <= yearEndRange; i++) {
            yearOption.push(i);
        }

        const skeletonLenArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

        return (
            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <h3 className="card-title">
                            <p className="text-primary">
                                Equipment Utilization Report
                            </p>
                        </h3>
                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className="form-group row">
                                    <label htmlFor="businessUnit" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Business Unit <span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <Select
                                            value={this.state.businessUnit}
                                            onChange={this.handleBuChange}
                                            options={this.state.allBusinessUnits}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="month" className="col-lg-2 col-form-label" style={{ "textAlign": "right" }}>Month <span className="required text-danger"> *</span></label>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <select className="form-control form-control-sm" id="month" name="month" value={this.state.month} onChange={this.handleMonthChange} >
                                                    <option value="">Select Month</option>
                                                    {monthOption.map((item, key) =>
                                                        <option key={key} value={item.value}>{item.label}</option>
                                                    )}
                                                </select>
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="form-group row">
                                                    <label htmlFor="year" className="col-lg-4 col-form-label" style={{ "textAlign": "right" }}>Year <span className="required text-danger"> *</span></label>
                                                    <div className="col-lg-8">
                                                        <select className="form-control form-control-sm" id="year" name="year" value={this.state.year} onChange={this.handleYearChange} >
                                                            <option value="">Select Year</option>
                                                            {yearOption.map((item, key) =>
                                                                <option key={key} value={item}>{item}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-lg-2">
                                    </div>
                                    <div className="col-lg-6">
                                        <button type="submit" className="btn btn-success mr-2">Show</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ "marginTop": "-60px" }}>
                            <div className="tableFixHead">
                                {this.state.reportData.length > 0 && <div className="card-toolbar mb-5 "><a href={`${excelExportEndPoint}/${this.state.businessUnit.projectId}/${this.state.month}/${this.state.year}?token=${token.token}`} className="btn btn-primary btn-sm mr-3"><i className="flaticon2-download-1"></i>Excel Export</a></div>}
                                <div className="table-responsive" style={{ maxHeight: "500px" }}>
                                    <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                        <thead>
                                            <tr>
                                                <th>SL No</th>
                                                <th>Equipment Name</th>
                                                <th>Equipment Code</th>
                                                <th>Month</th>
                                                <th>Emo No</th>
                                                <th>Running</th>
                                                <th>Stand By</th>
                                                <th>Breakdown</th>
                                                <th>Bill Periods</th>
                                                <th>Receive Date</th>
                                                <th>Release Date</th>
                                                <th>Status</th>
                                                <th>Location Name</th>
                                                <th>Working Section</th>
                                                <th>Remarks</th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {this.state.loading &&
                                                <tr>
                                                    {skeletonLenArr.map((item, index) =>
                                                        <td key={index}>
                                                            <p>
                                                                <Skeleton count={14} />
                                                            </p>
                                                        </td>
                                                    )}
                                                </tr>
                                            }
                                            {!this.state.loading && this.state.reportData.length > 0 ?
                                                this.state.reportData.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.equipmentName}</td>
                                                        <td>{item.equipmentCode}</td>
                                                        <td>{item.monthName}</td>
                                                        <td>{item.emoNo}</td>
                                                        <td>{item.logData["rHour"]}</td>
                                                        <td>{item.logData["iHour"]}</td>
                                                        <td>{item.logData["bHour"]}</td>
                                                        {item.billAmount ?
                                                            <td>{item.billAmount.split("-")[1] > 0 ? '1 Month' : item.billAmount.split("-")[2] + ' Days'}</td>
                                                            :
                                                            <td>N/A</td>
                                                        }

                                                        <td>{item.emoReceiveDate}</td>
                                                        <td>{item.releaseDate}</td>
                                                        <td>
                                                            {item.status === "created" && <span className="label label-primary label-inline mr-2">Created</span>}
                                                            {item.status === "received" && <span className="label label-warning label-inline mr-2">Received</span>}
                                                            {item.status === "denied" && <span className="label label-danger label-inline mr-2">Denied</span>}
                                                            {item.status === "released" && <span className="label label-success label-inline mr-2">Released</span>}
                                                            {item.status === "Released, Parked" && <span className="label label-info label-inline mr-2">Parked</span>}
                                                        </td>
                                                        <td>{item.locationName}</td>
                                                        <td>{item.logData["workingSection"]}</td>
                                                        <td>{item.remarks}</td>
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td colSpan="15"><p style={{ "textAlign": "center" }}></p></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <pre>
                            {JSON.stringify(this.state, null, 2)}
                        </pre> */}
                    </form>

                </div>

                {/* <ToastContainer /> */}
            </>
        );
    }
}

export default EquipmentUtilizationReport;