import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { AppContext } from '../context/AppContext';

class DenyMo extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            maintOrderId: "",
            moNo: "",
            reason: ""
        }
    }

    componentDidMount() {
        this.baseState = this.state;
        this.setState({ maintOrderId: this.props.maintOrderId, moNo: this.props.moNo });
    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    handleSubmit = evt => {
        evt.preventDefault();
        const isValid = true;

        if (isValid) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to deny this Maintenance Order !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }).then((result) => {
                if (result.value) {
                    const jwt = this.context.jwt.token;
                    const formData = {
                        maintOrderId: this.state.maintOrderId,
                        reason: this.state.reason
                    };
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                        body: JSON.stringify(formData)
                    };

                    var apiEnd = "maintenance_order/deny";

                    fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
                        .then((resp) => {
                            return resp.json()
                        })
                        .then((resp) => {
                            console.log(resp);

                            if (resp.success === true) {
                                let successMsg = [resp.successMessage];

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success',
                                    text: successMsg,
                                })
                                this.setState(this.baseState);
                                this.props.onDenyMo();
                            }
                            else {
                                //var errorsMessage = "";
                                var errorsMessage = [];

                                if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                    var errorsObj = resp.errorMessage;
                                    Object.keys(errorsObj).forEach(function (value) {
                                        errorsObj[value].forEach(function (v) {
                                            errorsMessage.push(v)
                                            //errorsMessage += '<div>' + v + '</div>';
                                        });

                                    });

                                } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                    //errorsMessage = resp.errorMessage;
                                    errorsMessage.push(resp.errorMessage);
                                } else {
                                    //errorsMessage = "Something went wrong";
                                    errorsMessage.push("Something went wrong");
                                }
                                //console.log(errorsMessage);
                                Swal.fire({
                                    icon: 'error',
                                    title: resp.heading,
                                    text: errorsMessage,
                                })
                            }


                        })
                        .catch((error) => {
                            console.log(error, "catch the hoop")
                        });

                }
            })
        }
    }

    render() {

        return (
            <>
                <h3 className='card-title'>
                    <p className='text-primary'>Deny MO</p>
                </h3>

                <form onSubmit={this.handleSubmit}>
                    <div className="card-body" style={{ marginTop: "-10px" }}>
                        <div className="ml-12">

                            <div className="form-group row">
                                <label htmlFor="moNo" className="col-lg-4 col-form-label">Maintenance Order No</label>
                                <div className="col-lg-6">
                                    <input type="text" name="moNo" id="moNo" value={this.state.moNo} className="form-control form-control-sm" readOnly />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="reason" className="col-lg-4 col-form-label">Reason <span className="required text-danger"> *</span> </label>
                                <div className="col-lg-6">
                                    <textarea name="reason" id="reason" value={this.state.reason} className="form-control form-control-sm" onChange={this.handleInputOnChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-6">
                        </div>
                        <div className="col-6">
                            <button type="submit" className="btn btn-success mr-2">Submit</button>
                        </div>
                    </div>
                </form>

                <ToastContainer />
            </>
        );
    }
}

export default DenyMo;