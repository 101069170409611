import axios from 'axios';
import React, { Component } from 'react';
import { Button } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import ToastMsg from '../components/ToastMsg';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGetRequestOptions } from "../components/GetToken";
import { AppContext } from '../context/AppContext';

class CreditNoteDetails extends Component {
    static contextType = AppContext;
    state = {
        creditNoteId: "",
        billingBu: { id: "", businessunitName: "" },
        billNo: "",
        billMonth: "",
        billDate: "",
        billYear: "",
        total: "",
        totalDeduction: "",
        type: "",
        status: "",
        category: "",
        cnNo: "",
        cnDate: "",
        refundAmount: "",
        remarks: "",
        items: [],
        statusHistory: [],
        selectedFile: null,
        file: [],
        approvalPermission: null
    }

    componentDidMount() {
        const {
            params: { id }
        } = this.props.match;
        this.setState({ creditNoteId: this.props.match.params.id });
        this.getCreditNoteDetails(id);
        this.getAllFiles("credit_note_details", id);
        this.getApprovalPermission();
    }

    getApprovalPermission() {
        fetch(process.env.REACT_APP_API_URL + "cn_approval_permission", getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ approvalPermission: resp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }

    getCreditNoteDetails(creditNoteId) {
        fetch(process.env.REACT_APP_API_URL + "credit_note_details/" + creditNoteId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({
                    billNo: resp.data.details.billNo,
                    billMonth: resp.data.details.billMonth,
                    billYear: resp.data.details.billYear,
                    billDate: resp.data.details.billDate,
                    total: Number(resp.data.details.total).toFixed(2),
                    refundAmount: Number(resp.data.details.refundAmount).toFixed(2),
                    billingBu: resp.data.details.billing_bu,
                    selectedFile: null,
                    statusHistory: resp.data.details.status_history,
                    cnNo: resp.data.details.cnNo,
                    cnDate: resp.data.details.cnDate,
                    status: resp.data.details.status,
                    remarks: resp.data.details.remarks
                });



            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });

    }

    cnApprove = (creditNoteId) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#C1C1C1',
            confirmButtonText: 'Yes, approve it!'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const formData = { creditNoteId: creditNoteId };

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };
                fetch(process.env.REACT_APP_API_URL + "credit_note_approve", requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {

                            toast.success(<ToastMsg toastMessage={[resp.successMessage]} heading="Credit Note approved successfully" />, {
                                position: toast.POSITION.TOP_RIGHT
                            });

                            this.getCreditNoteDetails(creditNoteId);
                            this.getAllFiles("credit_note_details", creditNoteId);

                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                        //errorsMessage += '<div>' + v + '</div>';
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                                //errorsMessage = resp.errorMessage;
                                errorsMessage.push(resp.errorMessage);
                            } else {
                                //errorsMessage = "Something went wrong";
                                errorsMessage.push("Something went wrong");
                            }
                            //console.log(errorsMessage);
                            toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                        }

                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });
            }
        })

    }



    getAllFiles(refType, refId) {
        fetch(process.env.REACT_APP_API_URL + "emd/get_all_files/" + refType + "/" + refId,
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ file: resp });

            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }


    onFileChange = event => {
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });
    };

    // On file upload (click the upload button) 
    onFileUpload = evt => {

        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to upload this file',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {

                const formData = new FormData();

                formData.append(
                    "myFile",
                    this.state.selectedFile,
                    this.state.selectedFile.name,
                );
                formData.append("refType", "credit_note_details");
                formData.append("refId", this.state.creditNoteId);
                const jwt = this.context.jwt.token;
                var authOptions = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + jwt },
                    url: process.env.REACT_APP_API_URL + "emd/file_upload",
                    data: formData,

                };
                axios(authOptions)
                    .then(response => {
                        console.log(response);
                        if (response.data.success === true) {

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'File uploaded successfully',
                            })

                            this.setState({ selectedFile: null });
                            this.getAllFiles("credit_note_details", this.state.creditNoteId);
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (response.data.errorMessage !== undefined && typeof response.data.errorMessage === 'object') {
                                var errorsObj = response.data.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                    });

                                });

                            } else if (response.data.errorMessage !== undefined && (typeof response.data.errorMessage === 'string' || response.data.errorMessage instanceof String)) {

                                errorsMessage.push(response.data.errorMessage);
                            } else {

                                errorsMessage.push("Something went wrong");
                            }
                            Swal.fire({
                                icon: 'error',
                                title: response.heading,
                                text: errorsMessage,
                            })

                        }
                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop");
                    })
            }
        })
    }

    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <>
                    <h3>File Details:</h3>
                    <p>File Name: {this.state.selectedFile.name}</p>
                    <p>File Type: {this.state.selectedFile.type}</p>
                </>
            );
        }
    };

    removeFile = fileId => evt => {
        evt.preventDefault();
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to delete this file',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.value) {
                const jwt = this.context.jwt.token;
                const { ...formData } = this.state;
                const requestOptions = {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
                    body: JSON.stringify(formData)
                };

                fetch(process.env.REACT_APP_API_URL + "emd/delete_file/" + fileId, requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        console.log(resp);

                        if (resp.success === true) {

                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: 'File deleted successfully',
                            })
                            this.getAllFiles("credit_note_details", this.state.creditNoteId);
                        }
                        else {
                            //var errorsMessage = "";
                            var errorsMessage = [];

                            if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                                var errorsObj = resp.errorMessage;
                                Object.keys(errorsObj).forEach(function (value) {
                                    errorsObj[value].forEach(function (v) {
                                        errorsMessage.push(v)
                                    });

                                });

                            } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {

                                errorsMessage.push(resp.errorMessage);
                            } else {

                                errorsMessage.push("Something went wrong");
                            }
                            Swal.fire({
                                icon: 'error',
                                title: resp.heading,
                                text: errorsMessage,
                            })

                        }

                    })
                    .catch((error) => {
                        console.log(error, "catch the hoop")
                    });

            }
        })
    }


    render() {
        return (<>
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label">
                            Credit Note No #{this.state.cnNo}
                        </h3>

                    </div>

                    <div className="card-toolbar mr-10 ">
                        {(this.state.status === 'created' && this.state.approvalPermission != null) && <Button className="btn btn-primary btn-sm float-right mb-5" onClick={() => this.cnApprove(this.state.creditNoteId)}>Approve </Button>}
                    </div>

                </div>


                <form>
                    <div className="card-body" style={{ "marginTop": "-25px" }}>
                        <div className="row">
                            <div className="col-6">
                                <table className="table table-bordered table-condensed">
                                    <tbody>
                                        <tr>
                                            <th style={{ 'width': '30%' }}>Credit Note  No</th>
                                            <td style={{ 'width': '70%' }}>{this.state.cnNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Credit Note Date</th>
                                            <td>{this.state.cnDate}</td>
                                        </tr>
                                        <tr>
                                            <th>Refund Amount</th>
                                            <td>{this.state.refundAmount}</td>
                                        </tr>
                                        <tr>
                                            <th>Bill No</th>
                                            <td>{this.state.billNo}</td>
                                        </tr>
                                        <tr>
                                            <th>Bill Month</th>
                                            <td>{this.state.billMonth + '-' + this.state.billYear}</td>
                                        </tr>
                                        <tr>
                                            <th>Bill To</th>
                                            <td>{this.state.billingBu.businessunitName}</td>
                                        </tr>
                                        <tr>
                                            <th>Credit Note Status</th>
                                            <td>{this.state.status === 'created' && <span className="label label-lg label-info label-inline mr-2">Created</span>}{this.state.status === 'approved' && <span className="label label-lg  label-success label-inline mr-2">Approved</span>}</td>
                                        </tr>
                                        <tr>
                                            <th>Remarks</th>
                                            <td>{this.state.remarks}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-6">
                                <div className="card card-custom">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h3 className="card-label">
                                                Timeline
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="timeline timeline-3">
                                            <div className="timeline-items">
                                                {
                                                    this.state.statusHistory.map((item, index) =>
                                                        <div className="timeline-item" key={index}>
                                                            <div className="timeline-media">
                                                                {item.status === "created" ? <i className="flaticon2-plus text-primary "></i>
                                                                    : <i className="flaticon2-check-mark text-success"></i>
                                                                }
                                                            </div>
                                                            <div className="timeline-content">
                                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                                    <div className="mr-4">
                                                                        <span className="mr-3 font-weight-bolder" style={{ "fontSize": "15px", "color": "#e35b5a" }}>
                                                                            Credit Note No {this.state.cnNo} has been
                                                                            {item.status === "created" ? " Created" : " Approved"}
                                                                            <span className="text-muted ml-2"> at {item.create_date}</span>
                                                                        </span>

                                                                        {item.status === "created" &&
                                                                            <p className="mr-3">
                                                                                Created By : <a href="/#"><b>{item.fullName}</b></a>
                                                                            </p>
                                                                        }
                                                                        {item.status === "approved" &&
                                                                            <p className="mr-3">
                                                                                Approved By : <a href="/#"><b>{item.fullName}</b></a>
                                                                            </p>
                                                                        }

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* <ToastContainer /> */}
        </>);
    }
}

export default CreditNoteDetails;