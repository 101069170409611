import React, { Component } from 'react';
import Select from 'react-select';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getGetRequestOptions } from "../../components/GetToken";
import ToastMsg from '../../components/ToastMsg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import "./../../../style/table.css";
import { AppContext } from '../../context/AppContext';

const customStylesSelect = {
    control: (provided) => ({
        ...provided,
        height: 'calc(1.35em + 1.1rem + 2px)',
        minHeight: '35px'
    }),
    valueContainer: (provided) => ({
        ...provided,
    })
};

class BreakdownMaintenanceReport extends Component {
    static contextType = AppContext;

    state = {
        isAll: 0,
        equipmentLocationBu: "",
        fromDate: "",
        toDate: "",
        reportData: [],
        allBusinessUnits: [],
        showReport: false
    }

    componentDidMount() {
        const { allBusinessUnits, ...baseState } = this.state;
        this.baseState = baseState;
        this.getAllBu();
    }

    handleChangeIsAll = evt => {
        if (evt.target.checked === true) {
            this.setState({ [evt.target.name]: 1 });
        }
        else {
            this.setState({ [evt.target.name]: 0 });
        }

    }

    handleInputOnChange = evt => {
        this.setState({ [evt.target.name]: evt.target.value });
    };


    handleBuChange = businessUnit => {
        this.setState({ equipmentLocationBu: businessUnit });
    }


    getAllBu() {
        fetch(process.env.REACT_APP_API_URL + "get_business_units",
            getGetRequestOptions())
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                let tempResp = resp.data.map(function (element) {
                    return { ...element, label: element.projectName, value: element.projectId }
                });
                this.setState({ allBusinessUnits: tempResp });
            })
            .catch((error) => {
                console.log(error, "catch the hoop")
            });
    }

    getReportData() {
        this.setState({ loading: true });

        const { equipmentLocationBu, fromDate, toDate, isAll } = this.state;

        const formData = {
            equipmentLocationBu: equipmentLocationBu,
            fromDate: fromDate,
            toDate: toDate,
            isAll: isAll
        };

        //console.log(formData);
        const jwt = this.context.jwt.token;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt },
            body: JSON.stringify(formData)
        };
        fetch(process.env.REACT_APP_API_URL + "reports/breakdown_maintenance_report", requestOptions)
            .then((resp) => {
                return resp.json()
            })
            .then((resp) => {
                this.setState({ loading: false });

                if (resp.success === true) {
                    this.setState({
                        reportData: resp.data
                    });
                }
                else {
                    //var errorsMessage = "";
                    var errorsMessage = [];

                    if (resp.errorMessage !== undefined && typeof resp.errorMessage === 'object') {
                        var errorsObj = resp.errorMessage;
                        Object.keys(errorsObj).forEach(function (value) {
                            errorsObj[value].forEach(function (v) {
                                errorsMessage.push(v)
                                //errorsMessage += '<div>' + v + '</div>';
                            });

                        });

                    } else if (resp.errorMessage !== undefined && (typeof resp.errorMessage === 'string' || resp.errorMessage instanceof String)) {
                        //errorsMessage = resp.errorMessage;
                        errorsMessage.push(resp.errorMessage);
                    } else {
                        //errorsMessage = "Something went wrong";
                        errorsMessage.push("Something went wrong");
                    }
                    //console.log(errorsMessage);
                    toast.error(<ToastMsg toastMessage={errorsMessage} heading={resp.heading} />, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                }


            })
            .catch((error) => {
                this.setState({ loading: false });
                console.log(error, "catch the hoop")
            });

    }


    handleSubmit = evt => {
        evt.preventDefault();
        this.setState({ showReport: true });
        this.getReportData();
    }

    render() {

        var endPoint = process.env.REACT_APP_API_URL.split("api/")[0] + 'report/breakdown_maintenance_excel_export';
        if (this.state.isAll === 1) {
            endPoint = endPoint + '/' + this.state.isAll + '/0/0/0';
        }
        else {
            endPoint = endPoint + '/' + this.state.isAll + '/' + (this.state.fromDate !== "" ? this.state.fromDate : 0) + '/' + (this.state.toDate !== "" ? this.state.toDate : 0) + '/' + (this.state.equipmentLocationBu !== "" ? this.state.equipmentLocationBu.value : 0);
        }
        const excelExportEndPoint = endPoint;
        const token = this.context.jwt;

        const skeletonLenArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
        return (

            <>
                <div className="card card-custom">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label"><p className="text-primary">Breakdown Maintenance Report</p></h3>
                        </div>

                    </div>

                    <form onSubmit={this.handleSubmit}>
                        <div className="card-body">
                            <div className="ml-12">
                                <div className="form-group row">
                                    <div className="row col-lg-2"></div>
                                    <div className="row col-lg-6" style={{ "marginLeft": "30px" }}>
                                        <div className="checkbox-inline">
                                            <label className="checkbox checkbox-lg">
                                                <input type="checkbox"
                                                    name="isAll"
                                                    checked={this.state.isAll === 1}
                                                    onChange={this.handleChangeIsAll}
                                                />
                                                <span></span>
                                                All Project
                                            </label>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label htmlFor="example-text-input" className="col-lg-2 col-form-label" style={{ "textAlign": "center" }}>Equipment Location BU</label>
                                    <div className="col-lg-6">
                                        <Select
                                            value={this.state.equipmentLocationBu}
                                            onChange={this.handleBuChange}
                                            options={this.state.allBusinessUnits}
                                            styles={customStylesSelect}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label htmlFor="fromDate" className="col-lg-2 col-form-label" style={{ "textAlign": "center" }}>From  Date </label>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <input type="date" name="fromDate" id="fromDate" value={this.state.fromDate} onChange={this.handleInputOnChange} className="form-control form-control-md" />
                                            </div>
                                            <div className="col-lg-7">
                                                <div className="form-group row">
                                                    <label htmlFor="toDate" className="col-lg-3 col-form-label" style={{ "textAlign": "center" }}>To Date </label>
                                                    <div className="col-lg-9">
                                                        <input type="date" name="toDate" id="toDate" value={this.state.toDate} onChange={this.handleInputOnChange} className="form-control form-control-md" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">

                                    <div className="col-lg-2">
                                    </div>
                                    <div className="col-lg-6">
                                        <button type="submit" className="btn btn-success mr-2">Show</button>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </form>

                    {this.state.showReport !== "" ?
                        <div className="card-body" style={{ "marginTop": "-60px" }}>
                            <div className="card-toolbar mt-5 mr-10  mb-5 ">
                                {this.state.reportData.length > 0 && <a href={`${excelExportEndPoint}?token=${token.token}`} className="btn btn-primary btn-sm mr-3"><i className="flaticon2-download-1"></i>Excel Export</a>}
                            </div>
                            <div className="tableFixHead">
                                <div className="table-responsive" style={{ maxHeight: "600px" }}>
                                    <table className="table table-bordered table-hover" style={{ minWidth: "max-content" }}>
                                        <thead>
                                            <tr>
                                                <th>SL</th>
                                                <th>MO Id</th>
                                                <th>MO No</th>
                                                <th>Order Date</th>
                                                <th>Equipment Condition</th>
                                                <th>Maintenance Type</th>
                                                <th>Priority</th>
                                                <th>Responsible Person</th>
                                                <th>Category</th>
                                                <th>Title</th>
                                                <th>Equipment</th>
                                                <th>Equipment Code</th>
                                                <th>Closing Hour Meter</th>
                                                <th>Creator's Name</th>
                                                <th>Equipment Location Bu</th>
                                                <th style={{ textAlign: "center" }}>Parts</th>
                                                <th style={{ textAlign: "center" }}>Operation</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {this.state.loading &&
                                                <tr>
                                                    {skeletonLenArr.map((item, index) =>
                                                        <td key={index}>
                                                            <p>
                                                                <Skeleton count={14} />
                                                            </p>
                                                        </td>
                                                    )}
                                                </tr>
                                            }
                                            {!this.state.loading && this.state.reportData.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.id_maint_order}</td>
                                                    <td>{item.orderNo}</td>
                                                    <td>{item.orderDate}</td>
                                                    <td>{item.equipmentCondition}</td>
                                                    <td>{item.maintenancetype !== null ? item.maintenancetype.name : ""}</td>
                                                    <td>{item.priority === "high" && "High"}{item.priority === "medium" && "Medium"}{item.priority === "low" && "Low"}</td>
                                                    <td>
                                                        <ul>
                                                            {item.responsibleperson.map((person, key) =>
                                                                <li key={key} >{`${person.employeeName} ( ${person.phoneNo} )`}</li>
                                                            )}
                                                        </ul>
                                                    </td>
                                                    <td>{item.category === "breakdown_maintenance" ? "Breakdown Maintenance" : "Schedule Maintenance"} </td>
                                                    <td>{item.title}</td>
                                                    <td>{item.equipment.equipmentName}</td>
                                                    <td>{item.equipment.equipmentCode}</td>
                                                    <td>{item.closingHrsMeter}</td>
                                                    <td>{item.maintenance_status.length > 0 ? item.maintenance_status[0].fullName : ""}</td>
                                                    <td>{item.maint_businessunit !== null ? item.maint_businessunit.maintBusinessunitName : ""}</td>
                                                    <td>
                                                        {item.parts.length > 0 &&
                                                            <table className="table table-bordered table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Warehouse</td>
                                                                        <td>Material Name</td>
                                                                        <td>Material Desc</td>
                                                                        <td>Item</td>
                                                                        <td>Unit</td>
                                                                        <td>Price</td>
                                                                        <td>Quantity</td>
                                                                        <td>Issue Quantity</td>
                                                                        <td>Cost</td>
                                                                        <td>Inventory Status</td>
                                                                        <td>Is Complete</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.parts.map((i, j) =>
                                                                        <tr key={j}>
                                                                            <td>{i.warehouseName}</td>
                                                                            <td>{i.materialName}</td>
                                                                            <td>{i.materialDesc}</td>
                                                                            <td>{i.item ? i.item.categoryName : ''}</td>
                                                                            <td>{i.item ? i.item.unitName : ''}</td>
                                                                            <td>{i.price}</td>
                                                                            <td>{i.quantity}</td>
                                                                            <td>{i.issuedQty}</td>
                                                                            <td>{(Number(i.price) * Number(i.quantity)).toFixed(2)}</td>
                                                                            <td>{i.invStatus}</td>
                                                                            <td style={{ textAlign: "center" }}><span className={i.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{i.isComplete === 'no' ? 'No' : 'Yes'}</span></td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </td>
                                                    <td>
                                                        {item.operations.length > 0 &&
                                                            <table className="table table-bordered table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <td>Operation</td>
                                                                        <td>Operation Short Desc</td>
                                                                        <td>Work Start Date</td>
                                                                        <td>Work Finish Date</td>
                                                                        <td>Duration (H:M)</td>
                                                                        <td>SRC Id</td>
                                                                        <td>Amount</td>
                                                                        <td>Is Complete</td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {item.operations.map((i, j) =>
                                                                        <tr key={j}>
                                                                            <td>{i.operationTitle}</td>
                                                                            <td>{i.operationDetails}</td>
                                                                            <td>{i.startDate}</td>
                                                                            <td>{i.endDate}</td>
                                                                            <td>{i.time}</td>
                                                                            <td>{i.srrNo}</td>
                                                                            <td>{Number(i.amount).toFixed(2)}</td>
                                                                            <td style={{ textAlign: "center" }}><span className={i.isComplete === 'no' ? 'label label-lg label-warning label-inline mr-2' : 'label label-lg label-success label-inline mr-2'}>{i.isComplete === 'no' ? 'No' : 'Yes'}</span></td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        }
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>{item.status === 'created' && <span className='label label-lg label-primary label-inline mr-2'>Created</span>}
                                                        {item.status === 'approved' && <span className='label label-lg label-info label-inline mr-2'>Approved</span>}
                                                        {item.status === 'denied' && <span className='label label-lg label-danger label-inline mr-2'>Denied</span>}
                                                        {item.status === 'completed' && <span className='label label-lg label-success label-inline mr-2'>Completed</span>}</td>
                                                </tr>
                                            )
                                            }
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div> : ""
                    }
                    {/* <pre>
                        {JSON.stringify(this.state, null, 2)}
                        {JSON.stringify(this.state, null, 2)}
                    </pre> */}
                </div>
                {/* <ToastContainer /> */}
            </>

        );
    }
}

export default BreakdownMaintenanceReport;